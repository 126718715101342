import React, { useState } from "react";
import { Redirect } from "react-router-dom";

import "./Cog2_Instructions.css";

const Cog2_Instructions = (props) => {
  const [ moveToLobby, setMoveToLobby ] = useState(false);
  const { roomId, playerNumber } = props.match.params;

  const handleJoinButtonClick = () => {
    setMoveToLobby(true);
  };

  return (
    <div className="main-container">
      <div>
        <p style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '35px' }}>Vocabulary (Advanced Vocabulary Task) - 2</p>
        <br />
        <p>
        Each problem in this test has five sets of letters with four letters in each set.<br />
        Four of the sets of letters are alike in some way.<br />
        You are to find the rule that makes these four sets alike.  <br />
        The fifth letter set is different from them and will not fit this rule.  <br />
        Draw an X through the set of letters that is different.
        <br /><br />
NOTE:  The rules will not be based on the sounds of sets of letters, the shapes of letters, or whether letter combinations form words or parts of words.
        <br />
        </p>
        <br />
        <p>
        EXAMPLES:  <br />
        A.   NOPQ         DEFL          ABCD          HIJK          UVWX<br />
        B.   NLIK         PLIK          QLIK          THIK          VLIK<br />
        </p>
        <br />
        <p>
        In Example A, four of the sets have letters in alphabetical order. Therefore correct answer is DEFL. <br />
        In example B, four of the sets contain letter L. Therefore correct answer is THIK.<br />
        </p>
        Your score will be the number of problems marked correctly minus a fraction of the number marked incorrectly.  <br />
        Therefore, it will not be to your advantage to guess unless you are able to eliminate one or more of the letter sets.
      </div>
      <div style={{margin: 'auto'}}>
        <button className="enter-room-button" onClick={handleJoinButtonClick}>
          Start Game
        </button>
      </div>
      {moveToLobby &&
        <Redirect to={`/cognitive2/${roomId}/${playerNumber}`} />
      }
    </div>
  );
};

export default Cog2_Instructions;
