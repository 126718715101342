import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Form, Button } from "react-bootstrap";

import "./Cognitive1.css";

const Cognitive2 = (props) => {
  let history = useHistory();
  const { roomId, playerNumber } = props.match.params;

  const [ validated, setValidated ] = useState(false);

  const [ q1, setQ1 ] = React.useState("");
  const [ q2, setQ2 ] = React.useState("");
  const [ q3, setQ3 ] = React.useState("");
  const [ q4, setQ4 ] = React.useState("");
  const [ q5, setQ5 ] = React.useState("");
  const [ q6, setQ6 ] = React.useState("");
  const [ q7, setQ7 ] = React.useState("");
  const [ q8, setQ8 ] = React.useState("");
  const [ q9, setQ9 ] = React.useState("");
  const [ q10, setQ10 ] = React.useState("");
  const [ q11, setQ11 ] = React.useState("");
  const [ q12, setQ12 ] = React.useState("");
  const [ q13, setQ13 ] = React.useState("");
  const [ q14, setQ14 ] = React.useState("");
  const [ q15, setQ15 ] = React.useState("");



  const handleSubmit = (event) => {

    event.preventDefault();
    console.log("Cognitive form Survey form submitted")
    let request = {
      "roomid": roomId,
      "player_num": "Player " + playerNumber,
      "q1": q1,
      "q2": q2,
      "q3": q3,
      "q4": q4,
      "q5": q5,
      "q6": q6,
      "q7": q7,
      "q8": q8,
      "q9": q9,
      "q10": q10,
      "q11": q11,
      "q12": q12,
      "q13": q13,
      "q14": q14,
      "q15": q15,
    };
    
    console.log(request);

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    };
    fetch('http://boardgame.cogsearch.web.illinois.edu/cognitive2', options)
      .then(response => response.json())
      .then(data => console.log(data));

    console.log(request);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {

    }
    setValidated(true);
    history.push(`/cog3instructions/${roomId}/${playerNumber}`);
  };

  return (
    <div className="demo-container">
      <h2> Conginitive Questionnaire 3</h2>

      <br />
      <h4>Please answer the following questions:</h4>
      <Form>
        <Form.Label>Find the odd one out.</Form.Label>
        <Form.Group className="mb-3" controlId="q1">
          <span style={{display: "inline-block"}}>
          <span style={{ display: "inline-block", margin: "10px" }}>
               <Form.Check value="1" label="QPPQ"  type='radio'  checked={q1 == "QPPQ"} onChange={e => setQ1(e.target.value)}/>
               <Form.Check value="2" label="HGHH"  type='radio'  checked={q1 == "HGHH"} onChange={e => setQ1(e.target.value)}/>
               <Form.Check value="3" label="TTTU"  type='radio'  checked={q1 == "TTTU"} onChange={e => setQ1(e.target.value)}/>
               <Form.Check value="4" label="DDDE"  type='radio'  checked={q1 == "DDDE"} onChange={e => setQ1(e.target.value)}/>
               <Form.Check value="5" label="MLMM"  type='radio'  checked={q1 == "MLMM"} onChange={e => setQ1(e.target.value)}/>
               <Form.Check value="6" label="I don't know"  type='radio'  checked={q1 == "I don't know"} onChange={e => setQ1(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q2">
          <span style={{display: "inline-block"}}>
          <span style={{ display: "inline-block", margin: "10px" }}>
               <Form.Check value="1" label="BCDE"  type='radio'  checked={q2 == "BCDE"} onChange={e => setQ2(e.target.value)}/>
               <Form.Check value="2" label="FGHI"  type='radio'  checked={q2 == "FGHI"} onChange={e => setQ2(e.target.value)}/>
               <Form.Check value="3" label="JKLM"  type='radio'  checked={q2 == "JKLM"} onChange={e => setQ2(e.target.value)}/>
               <Form.Check value="4" label="PRST"  type='radio'  checked={q2 == "PRST"} onChange={e => setQ2(e.target.value)}/>
               <Form.Check value="5" label="VWXY"  type='radio'  checked={q2 == "VWXY"} onChange={e => setQ2(e.target.value)}/>
               <Form.Check value="6" label="I don't know"  type='radio'  checked={q2 == "I don't know"} onChange={e => setQ2(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q3">
          <span style={{display: "inline-block"}}>
          <span style={{ display: "inline-block", margin: "10px" }}>
               <Form.Check value="1" label="BVZC"  type='radio'  checked={q3 == "BVZC"} onChange={e => setQ3(e.target.value)}/>
               <Form.Check value="2" label="FVZG"  type='radio'  checked={q3 == "FVZG"} onChange={e => setQ3(e.target.value)}/>
               <Form.Check value="3" label="JVZK"  type='radio'  checked={q3 == "JVZK"} onChange={e => setQ3(e.target.value)}/>
               <Form.Check value="4" label="PWXQ"  type='radio'  checked={q3 == "PWXQ"} onChange={e => setQ3(e.target.value)}/>
               <Form.Check value="5" label="SVZT"  type='radio'  checked={q3 == "SVZT"} onChange={e => setQ3(e.target.value)}/>
               <Form.Check value="6" label="I don't know"  type='radio'  checked={q3 == "I don't know"} onChange={e => setQ3(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q4">
          <span style={{display: "inline-block"}}>
          <span style={{ display: "inline-block", margin: "10px" }}>
               <Form.Check value="1" label="BCEF"  type='radio'  checked={q4 == "BCEF"} onChange={e => setQ4(e.target.value)}/>
               <Form.Check value="2" label="FGIJ"  type='radio'  checked={q4 == "FGIJ"} onChange={e => setQ4(e.target.value)}/>
               <Form.Check value="3" label="STWX"  type='radio'  checked={q4 == "STWX"} onChange={e => setQ4(e.target.value)}/>
               <Form.Check value="4" label="CDFG"  type='radio'  checked={q4 == "CDFG"} onChange={e => setQ4(e.target.value)}/>
               <Form.Check value="5" label="PQST"  type='radio'  checked={q4 == "PQST"} onChange={e => setQ4(e.target.value)}/>
               <Form.Check value="6" label="I don't know"  type='radio'  checked={q4 == "I don't know"} onChange={e => setQ4(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q5">
          <span style={{display: "inline-block"}}>
          <span style={{ display: "inline-block", margin: "10px" }}>
               <Form.Check value="1" label="BCCB"  type='radio'  checked={q5 == "BCCB"} onChange={e => setQ5(e.target.value)}/>
               <Form.Check value="2" label="GFFG"  type='radio'  checked={q5 == "GFFG"} onChange={e => setQ5(e.target.value)}/>
               <Form.Check value="3" label="LMML"  type='radio'  checked={q5 == "LMML"} onChange={e => setQ5(e.target.value)}/>
               <Form.Check value="4" label="QRRQ"  type='radio'  checked={q5 == "QRRQ"} onChange={e => setQ5(e.target.value)}/>
               <Form.Check value="5" label="WXXW"  type='radio'  checked={q5 == "WXXW"} onChange={e => setQ5(e.target.value)}/>
               <Form.Check value="6" label="I don't know"  type='radio'  checked={q5 == "I don't know"} onChange={e => setQ5(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q6">
          <span style={{display: "inline-block"}}>
          <span style={{ display: "inline-block", margin: "10px" }}>
               <Form.Check value="1" label="AAPP"  type='radio'  checked={q6 == "AAPP"} onChange={e => setQ6(e.target.value)}/>
               <Form.Check value="2" label="CCRR"  type='radio'  checked={q6 == "CCRR"} onChange={e => setQ6(e.target.value)}/>
               <Form.Check value="3" label="QQBB"  type='radio'  checked={q6 == "QQBB"} onChange={e => setQ6(e.target.value)}/>
               <Form.Check value="4" label="EETT"  type='radio'  checked={q6 == "EETT"} onChange={e => setQ6(e.target.value)}/>
               <Form.Check value="5" label="DDSS"  type='radio'  checked={q6 == "DDSS"} onChange={e => setQ6(e.target.value)}/>
               <Form.Check value="6" label="I don't know"  type='radio'  checked={q6 == "I don't know"} onChange={e => setQ6(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q7">
          <span style={{display: "inline-block"}}>
          <span style={{ display: "inline-block", margin: "10px" }}>
               <Form.Check value="1" label="ABDC"  type='radio'  checked={q7 == "ABDC"} onChange={e => setQ7(e.target.value)}/>
               <Form.Check value="2" label="EGFH"  type='radio'  checked={q7 == "EGFH"} onChange={e => setQ7(e.target.value)}/>
               <Form.Check value="3" label="IJLK"  type='radio'  checked={q7 == "IJLK"} onChange={e => setQ7(e.target.value)}/>
               <Form.Check value="4" label="OPRQ"  type='radio'  checked={q7 == "OPRQ"} onChange={e => setQ7(e.target.value)}/>
               <Form.Check value="5" label="UVXW"  type='radio'  checked={q7 == "UVXW"} onChange={e => setQ7(e.target.value)}/>
               <Form.Check value="6" label="I don't know"  type='radio'  checked={q7 == "I don't know"} onChange={e => setQ7(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q8">
          <span style={{display: "inline-block"}}>
          <span style={{ display: "inline-block", margin: "10px" }}>
               <Form.Check value="1" label="CERT"  type='radio'  checked={q8 == "CERT"} onChange={e => setQ8(e.target.value)}/>
               <Form.Check value="2" label="KMTV"  type='radio'  checked={q8 == "KMTV"} onChange={e => setQ8(e.target.value)}/>
               <Form.Check value="3" label="FHXZ"  type='radio'  checked={q8 == "FHXZ"} onChange={e => setQ8(e.target.value)}/>
               <Form.Check value="4" label="BODQ"  type='radio'  checked={q8 == "BODQ"} onChange={e => setQ8(e.target.value)}/>
               <Form.Check value="5" label="HJPR"  type='radio'  checked={q8 == "HJPR"} onChange={e => setQ8(e.target.value)}/>
               <Form.Check value="6" label="I don't know"  type='radio'  checked={q8 == "I don't know"} onChange={e => setQ8(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q9">
          <span style={{display: "inline-block"}}>
          <span style={{ display: "inline-block", margin: "10px" }}>
               <Form.Check value="1" label="PABQ"  type='radio'  checked={q9 == "PABQ"} onChange={e => setQ9(e.target.value)}/>
               <Form.Check value="2" label="SEFT"  type='radio'  checked={q9 == "SEFT"} onChange={e => setQ9(e.target.value)}/>
               <Form.Check value="3" label="VIJW"  type='radio'  checked={q9 == "VIJW"} onChange={e => setQ9(e.target.value)}/>
               <Form.Check value="4" label="COPD"  type='radio'  checked={q9 == "COPD"} onChange={e => setQ9(e.target.value)}/>
               <Form.Check value="5" label="FUZG"  type='radio'  checked={q9 == "FUZG"} onChange={e => setQ9(e.target.value)}/>
               <Form.Check value="6" label="I don't know"  type='radio'  checked={q9 == "I don't know"} onChange={e => setQ9(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q10">
          <span style={{display: "inline-block"}}>
          <span style={{ display: "inline-block", margin: "10px" }}>
               <Form.Check value="1" label="CFCR"  type='radio'  checked={q10 == "CFCR"} onChange={e => setQ10(e.target.value)}/>
               <Form.Check value="2" label="JCVC"  type='radio'  checked={q10 == "JCVC"} onChange={e => setQ10(e.target.value)}/>
               <Form.Check value="3" label="CGCS"  type='radio'  checked={q10 == "CGCS"} onChange={e => setQ10(e.target.value)}/>
               <Form.Check value="4" label="CLXC"  type='radio'  checked={q10 == "CLXC"} onChange={e => setQ10(e.target.value)}/>
               <Form.Check value="5" label="KCWC"  type='radio'  checked={q10 == "KCWC"} onChange={e => setQ10(e.target.value)}/>
               <Form.Check value="6" label="I don't know"  type='radio'  checked={q10 == "I don't know"} onChange={e => setQ10(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q11">
          <span style={{display: "inline-block"}}>
          <span style={{ display: "inline-block", margin: "10px" }}>
               <Form.Check value="1" label="XDBK"  type='radio'  checked={q11 == "XDBK"} onChange={e => setQ11(e.target.value)}/>
               <Form.Check value="2" label="TNLL"  type='radio'  checked={q11 == "TNLL"} onChange={e => setQ11(e.target.value)}/>
               <Form.Check value="3" label="VEGV"  type='radio'  checked={q11 == "VEGV"} onChange={e => setQ11(e.target.value)}/>
               <Form.Check value="4" label="PFCC"  type='radio'  checked={q11 == "PFCC"} onChange={e => setQ11(e.target.value)}/>
               <Form.Check value="5" label="ZAGZ"  type='radio'  checked={q11 == "ZAGZ"} onChange={e => setQ11(e.target.value)}/>
               <Form.Check value="6" label="I don't know"  type='radio'  checked={q11 == "I don't know"} onChange={e => setQ11(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q12">
          <span style={{display: "inline-block"}}>
          <span style={{ display: "inline-block", margin: "10px" }}>
               <Form.Check value="1" label="CAEZ"  type='radio'  checked={q12 == "CAEZ"} onChange={e => setQ12(e.target.value)}/>
               <Form.Check value="2" label="CEIZ"  type='radio'  checked={q12 == "CEIZ"} onChange={e => setQ12(e.target.value)}/>
               <Form.Check value="3" label="CIOZ"  type='radio'  checked={q12 == "CIOZ"} onChange={e => setQ12(e.target.value)}/>
               <Form.Check value="4" label="CGVZ"  type='radio'  checked={q12 == "CGVZ"} onChange={e => setQ12(e.target.value)}/>
               <Form.Check value="5" label="CAUZ"  type='radio'  checked={q12 == "CAUZ"} onChange={e => setQ12(e.target.value)}/>
               <Form.Check value="6" label="I don't know"  type='radio'  checked={q12 == "I don't know"} onChange={e => setQ12(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q13">
          <span style={{display: "inline-block"}}>
          <span style={{ display: "inline-block", margin: "10px" }}>
               <Form.Check value="1" label="VEBT"  type='radio'  checked={q13 == "VEBT"} onChange={e => setQ13(e.target.value)}/>
               <Form.Check value="2" label="XGDV"  type='radio'  checked={q13 == "XGDV"} onChange={e => setQ13(e.target.value)}/>
               <Form.Check value="3" label="ZIFX"  type='radio'  checked={q13 == "ZIFX"} onChange={e => setQ13(e.target.value)}/>
               <Form.Check value="4" label="KXVH"  type='radio'  checked={q13 == "KXVH"} onChange={e => setQ13(e.target.value)}/>
               <Form.Check value="5" label="MZXJ"  type='radio'  checked={q13 == "MZXJ"} onChange={e => setQ13(e.target.value)}/>
               <Form.Check value="6" label="I don't know"  type='radio'  checked={q13 == "I don't know"} onChange={e => setQ13(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q14">
          <span style={{display: "inline-block"}}>
          <span style={{ display: "inline-block", margin: "10px" }}>
               <Form.Check value="1" label="AFBG"  type='radio'  checked={q14 == "AFBG"} onChange={e => setQ14(e.target.value)}/>
               <Form.Check value="2" label="EJFK"  type='radio'  checked={q14 == "EJFK"} onChange={e => setQ14(e.target.value)}/>
               <Form.Check value="3" label="GKHM"  type='radio'  checked={q14 == "GKHM"} onChange={e => setQ14(e.target.value)}/>
               <Form.Check value="4" label="PSQT"  type='radio'  checked={q14 == "PSQT"} onChange={e => setQ14(e.target.value)}/>
               <Form.Check value="5" label="RWSX"  type='radio'  checked={q14 == "RWSX"} onChange={e => setQ14(e.target.value)}/>
               <Form.Check value="6" label="I don't know"  type='radio'  checked={q14 == "I don't know"} onChange={e => setQ14(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q15">
          <span style={{display: "inline-block"}}>
          <span style={{ display: "inline-block", margin: "10px" }}>
               <Form.Check value="1" label="KGDB"  type='radio'  checked={q15 == "KGDB"} onChange={e => setQ15(e.target.value)}/>
               <Form.Check value="2" label="DFIM"  type='radio'  checked={q15 == "DFIM"} onChange={e => setQ15(e.target.value)}/>
               <Form.Check value="3" label="KIFB"  type='radio'  checked={q15 == "KIFB"} onChange={e => setQ15(e.target.value)}/>
               <Form.Check value="4" label="HJMQ"  type='radio'  checked={q15 == "HJMQ"} onChange={e => setQ15(e.target.value)}/>
               <Form.Check value="5" label="LHEC"  type='radio'  checked={q15 == "LHEC"} onChange={e => setQ15(e.target.value)}/>
               <Form.Check value="6" label="I don't know"  type='radio'  checked={q15 == "I don't know"} onChange={e => setQ15(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
      </Form>
      <Button variant="primary" type="submit" onClick={(e) => handleSubmit(e)}>
          Submit
        </Button>
    </div >
  );
};

export default Cognitive2;
