import React, { useEffect, useState } from "react";
import { Form, Button, Row, Card } from 'react-bootstrap';
import CardConfig from '../CardConfig.json';

import "./MidGameSurvey.css";

const MidGameSurvey = (props) => {
  let { setShowMidgame, setPrevroundAndResetTimer, round, roomId, playerNumber, gameNum, midGameQuestions, displayDelay } = props
  console.log(props)
  
  const [show, setShow] = useState(false);
  const [ q1, setQ1 ] = React.useState(null);
  const [ q2, setQ2 ] = React.useState(null);
  const [ q3, setQ3 ] = React.useState(null);
  const [ q4, setQ4 ] = React.useState(null);
  const [ q5, setQ5 ] = React.useState(null);
  const cards = CardConfig.cards;
 // alert("inside midgamesurvey component")
 console.log("Inside Mid Game Survey component")
  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, displayDelay);
  }, [setShow, displayDelay]);

  const handleSubmit = (event) => {

    if (q1 === null || q2 === null || q3 === null || q4 === null || q5 === null) {
      event.preventDefault();
      event.stopPropagation();
    }
    else{
    //event.preventDefault();
    console.log("Mid game Survey form submitted")
    let request = {
      "roomid": roomId,
      "game_num": gameNum,
      "round_num": round,
      "player_num": "Player " + playerNumber,
      "q1_res": q1,
      "q2_res": q2,
      "q3_res": q3,
      "q4_res": q4,
      "q5_res": q5,
      "game_end": false
    };
    
    console.log(request);

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    };
    fetch('http://boardgame.cogsearch.web.illinois.edu/midgamesurvey', options)
      .then(response => response.json())
      .then(data => console.log(data));

    console.log(request);

    setShowMidgame(false);

    console.log("ismidgame is set to false *********")

    setPrevroundAndResetTimer(round);
  }
  };

  if (!show) {
    console.log("mid game showing null")
    return null
  };

  return (
    <div className="box">
      <Form>
        <Form.Label>Mid Game Survey</Form.Label>
        <Form.Group className="mb-3" controlId="q1">
          <Form.Label>{midGameQuestions[0]}</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "inline-block", margin: "10px" }}>
               <Form.Check inline value={midGameQuestions[5].split(',')[0]} label={midGameQuestions[5].split(',')[0]}  type='radio'  checked={q1 == midGameQuestions[5].split(',')[0]} onChange={e => setQ1(e.target.value)}/>
               <Form.Check inline value={midGameQuestions[5].split(',')[1]} label={midGameQuestions[5].split(',')[1]}  type='radio'  checked={q1 == midGameQuestions[5].split(',')[1]} onChange={e => setQ1(e.target.value)}/>
               <Form.Check inline value={midGameQuestions[5].split(',')[2]} label={midGameQuestions[5].split(',')[2]}  type='radio'  checked={q1 == midGameQuestions[5].split(',')[2]} onChange={e => setQ1(e.target.value)}/>
               <Form.Check inline value={midGameQuestions[5].split(',')[3]} label={midGameQuestions[5].split(',')[3]}  type='radio'  checked={q1 == midGameQuestions[5].split(',')[3]} onChange={e => setQ1(e.target.value)}/>
               {/* <Form.Check inline value={midGameQuestions[6].split(',')[4]} label={midGameQuestions[6].split(',')[4]}  type='radio'  checked={q1 == midGameQuestions[6].split(',')[4]} onChange={e => setQ1(e.target.value)}/>       */}
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q2">
          <Form.Label>{midGameQuestions[1]}</Form.Label><br/>
          <span style={{display: "inline-block"}}>
            <span style={{ display: "inline-block", margin: "10px" }}>
               <Form.Check inline value={midGameQuestions[6].split(',')[0]} label={midGameQuestions[6].split(',')[0]}  type='radio'  checked={q2 == midGameQuestions[6].split(',')[0]} onChange={e => setQ2(e.target.value)}/>
               <Form.Check inline value={midGameQuestions[6].split(',')[1]} label={midGameQuestions[6].split(',')[1]}  type='radio'  checked={q2 == midGameQuestions[6].split(',')[1]} onChange={e => setQ2(e.target.value)}/>
               <Form.Check inline value={midGameQuestions[6].split(',')[2]} label={midGameQuestions[6].split(',')[2]}  type='radio'  checked={q2 == midGameQuestions[6].split(',')[2]} onChange={e => setQ2(e.target.value)}/>
               <Form.Check inline value={midGameQuestions[6].split(',')[3]} label={midGameQuestions[6].split(',')[3]}  type='radio'  checked={q2 == midGameQuestions[6].split(',')[3]} onChange={e => setQ2(e.target.value)}/>
               {/* <Form.Check inline value={midGameQuestions[7].split(',')[4]} label={midGameQuestions[7].split(',')[4]}  type='radio'  checked={q2 == midGameQuestions[7].split(',')[4]} onChange={e => setQ2(e.target.value)}/>       */}
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q3">
          <Form.Label>{midGameQuestions[2]}</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "inline-block", margin: "10px" }}>
               <Form.Check inline value={midGameQuestions[7].split(',')[0]} label={midGameQuestions[7].split(',')[0]}  type='radio'  checked={q3 == midGameQuestions[7].split(',')[0]} onChange={e => setQ3(e.target.value)}/>
               <Form.Check inline value={midGameQuestions[7].split(',')[1]} label={midGameQuestions[7].split(',')[1]}  type='radio'  checked={q3 == midGameQuestions[7].split(',')[1]} onChange={e => setQ3(e.target.value)}/>
               <Form.Check inline value={midGameQuestions[7].split(',')[2]} label={midGameQuestions[7].split(',')[2]}  type='radio'  checked={q3 == midGameQuestions[7].split(',')[2]} onChange={e => setQ3(e.target.value)}/>
               <Form.Check inline value={midGameQuestions[7].split(',')[3]} label={midGameQuestions[7].split(',')[3]}  type='radio'  checked={q3 == midGameQuestions[7].split(',')[3]} onChange={e => setQ3(e.target.value)}/>
               {/* <Form.Check inline value={midGameQuestions[8].split(',')[4]} label={midGameQuestions[8].split(',')[4]}  type='radio'  checked={q3 == midGameQuestions[8].split(',')[4]} onChange={e => setQ3(e.target.value)}/>       */}
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q4">
          <Form.Label>{midGameQuestions[3]}</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "inline-block", margin: "10px" }}>
               <Form.Check inline value={midGameQuestions[8].split(',')[0]} label={midGameQuestions[8].split(',')[0]}  type='radio'  checked={q4 == midGameQuestions[8].split(',')[0]} onChange={e => setQ4(e.target.value)}/>
               <Form.Check inline value={midGameQuestions[8].split(',')[1]} label={midGameQuestions[8].split(',')[1]}  type='radio'  checked={q4 == midGameQuestions[8].split(',')[1]} onChange={e => setQ4(e.target.value)}/>
               <Form.Check inline value={midGameQuestions[8].split(',')[2]} label={midGameQuestions[8].split(',')[2]}  type='radio'  checked={q4 == midGameQuestions[8].split(',')[2]} onChange={e => setQ4(e.target.value)}/>
               <Form.Check inline value={midGameQuestions[8].split(',')[3]} label={midGameQuestions[8].split(',')[3]}  type='radio'  checked={q4 == midGameQuestions[8].split(',')[3]} onChange={e => setQ4(e.target.value)}/>
               {/* <Form.Check inline value={midGameQuestions[9].split(',')[4]} label={midGameQuestions[9].split(',')[4]}  type='radio'  checked={q4 == midGameQuestions[9].split(',')[4]} onChange={e => setQ4(e.target.value)}/>       */}
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q5">
          <Form.Label>{midGameQuestions[4]}</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "inline-block", margin: "10px" }}>
               <Form.Check inline value={midGameQuestions[9].split(',')[0]} label={midGameQuestions[9].split(',')[0]}  type='radio'  checked={q5 == midGameQuestions[9].split(',')[0]} onChange={e => setQ5(e.target.value)}/>
               <Form.Check inline value={midGameQuestions[9].split(',')[1]} label={midGameQuestions[9].split(',')[1]}  type='radio'  checked={q5 == midGameQuestions[9].split(',')[1]} onChange={e => setQ5(e.target.value)}/>
               <Form.Check inline value={midGameQuestions[9].split(',')[2]} label={midGameQuestions[9].split(',')[2]}  type='radio'  checked={q5 == midGameQuestions[9].split(',')[2]} onChange={e => setQ5(e.target.value)}/>
               <Form.Check inline value={midGameQuestions[9].split(',')[3]} label={midGameQuestions[9].split(',')[3]}  type='radio'  checked={q5 == midGameQuestions[9].split(',')[3]} onChange={e => setQ5(e.target.value)}/>
               {/* <Form.Check inline value={midGameQuestions[10].split(',')[4]} label={midGameQuestions[10].split(',')[4]}  type='radio'  checked={q5 == midGameQuestions[10].split(',')[4]} onChange={e => setQ5(e.target.value)}/>       */}
            </span>
          </span>
        </Form.Group>

        <Button variant="primary" type="submit" onClick={(e) => handleSubmit(e)}>
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default MidGameSurvey;
