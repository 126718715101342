import { useEffect, useRef, useState } from "react";
import socketIOClient from "socket.io-client";

// Message types
const NEW_CARD_MESSAGE_EVENT = "newCardMessage";
const ROUND_MESSAGE_EVENT = "roundMessage";
const NEW_JOIN_EVENT = "newJoin";
const GAME_END_EVENT = "gameEnd";
//const GAME_END_EVENT_1 = "gameEnd1";
const MID_GAME_EVENT = "midGame";
const ROOM_END_EVENT = "roomEnd";
const SURVEY_SUBMIT_EVENT = "surveySubmit";
//const GAME_END_EVENT_FINAL_DECISION = "gameendeventfinaldecision"; //Jul5
//const GAME_END_EVENT_DISCONNECT = "gameenddisconnect";

//const SOCKET_SERVER_URL = "http://localhost:4000";
const SOCKET_SERVER_URL = "http://nodeapp.cogsearch.web.illinois.edu";


const useChat = (roomId, playerNumber) => {
  const [ messages, setMessages ] = useState([]);
  const [ round, setRound ] = useState(1);
  const [ members, setMembers ] = useState([]); // add a new state to check the survey form responses
  const [ cardSentForRound, setCardSentForRound ] = useState(0);
  const [ gameEnd, setGameEnd ] = useState(false);
  const [ roomEnd, setRoomEnd ] = useState(false);
  const [ midGame, setMidGame ] = useState(false);
  const [ countofsurvey, setCountOfSubmit ] = useState("hello"); //yolo
  const socketRef = useRef();

  useEffect(() => {
    socketRef.current = socketIOClient(SOCKET_SERVER_URL, {
      query: { roomId, playerNumber },
    });

    socketRef.current.on(NEW_CARD_MESSAGE_EVENT, (message) => {
      const incomingMessage = {
        ...message,
        ownedByCurrentUser: message.senderId === socketRef.current.id,
      };
      console.log("useChat message ************ "+message)
      setMessages((messages) => [...messages, incomingMessage]);
    });

    socketRef.current.on(ROUND_MESSAGE_EVENT, (round) => {
      console.log("useChat: Round msg event called"+ round);
      setRound(round);
    });

    socketRef.current.on(MID_GAME_EVENT, () => {
      console.log("useChat: Mid Game event called ***********"+ round);
      setMidGame(true);
    });

    socketRef.current.on(NEW_JOIN_EVENT, (memberInfoList) => {
      setMembers(memberInfoList);
    });

    socketRef.current.on(SURVEY_SUBMIT_EVENT, (count) => { //yolo
      //setsurveysubmit(false);
      console.log("Avg: check the count of submits"+ count);
      setCountOfSubmit(count);
    });

    socketRef.current.on(GAME_END_EVENT, () => {
      // TODO: save all the game information to DB
      //console.log("******* Disconnect happening now ********* "+socketRef)
      // redirect all users to a new game page
      socketRef.current.disconnect(); //Jul5
      setGameEnd(true);
    });

    // socketRef.current.on(GAME_END_EVENT_DISCONNECT, () => {
    //   // TODO: save all the game information to DB
    //   console.log("******* Disconnect happening now ********* "+socketRef)
    //   // redirect all users to a new game page
    //   socketRef.current.disconnect(); //Jul5
    //   //setGameEnd(true);
    // });

    socketRef.current.on(ROOM_END_EVENT, () => {
      socketRef.current.disconnect();
      setRoomEnd(true);
    });

    return () => {
      // socketRef.current.disconnect();
    };
  }, [ roomId, socketRef, playerNumber ]);

  let player_number = "Unknown";
  for (var i = 0, l = members.length; i < l; i++) {
    if(members[i]["socketid"] === socketRef.current.id) {
      player_number = "Player " + members[i]["number"];
      break;
    }
  }


  const sendMessage = (messageBody, round, cardDescription) => {
    if(cardSentForRound < round) {
      setCardSentForRound(round);
      let messsageJson = {
        card : messageBody, 
        body: messageBody + " | sent by: " + player_number,
        description: cardDescription,
        senderId: socketRef.current.id,
        computerPlayer: false,
        playerNo: player_number
      };
      socketRef.current.emit(NEW_CARD_MESSAGE_EVENT, messsageJson);

      return messsageJson;
    }
  };

  const sendSurveySubmit = (val) => {
    console.log("Avg: inside sendSurveySubmit ",val);
    //setsurveysubmit(val)
    socketRef.current.emit(SURVEY_SUBMIT_EVENT, val);
      return val; 
  };


 return { setMidGame, countofsurvey, sendSurveySubmit, messages, round, members, player_number, gameEnd, roomEnd, midGame, sendMessage}; 
};

export default useChat;
