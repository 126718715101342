import React from "react";

import "./EndPage.css";

const EndPage = () => {

  return (
    <div className="home-container">
      <h2>Thank you very much participating in the study. We are grateful for your contributions to the research. 
Please copy this reward code “C1GG3ORD” back to the site to claim the completion of this task. The compensation will be transferred to your account once confirming your response. Thank you very much.
</h2>
    </div>
  );
};

export default EndPage;
