import React, {useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Form, Button, Row, Card } from 'react-bootstrap';
import "./FinalDecision.css";
//import useChat from "../useChat"; //Jul5

const FinalDecision = (props) => {
  let history = useHistory();
  const { roomId, playerNumber } = props.match.params;

  let members1 = props.location.state.members;
  let currentPlayer = props.location.state.currentPlayer;
  let finalGame = props.location.state.finalGame;
  let gameNum = props.location.state.gameNum;
  let final = props.location.state.final;
  console.log("Is it a final game?: "+ finalGame)
  console.log("Is final 0??: "+ final)

  //const { countofsurvey, sendSurveySubmit, messages, round, members, player_number, gameEnd, roomEnd, midGame, sendMessage } = useChat(roomId, playerNumber);//Jul5

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!finalGame) {
        console.log("finaldecision.jsx : Eesha not final game");
      //  sendGameEndEvent(true); //Jul5
        history.push(`/room/${roomId}/${playerNumber}?game=${gameNum + 1}`);
      } else {
        console.log("Eesha final game "+gameNum);
        history.push(`/reflections/${roomId}/${playerNumber}`, {"members": members1, "currentPlayer": currentPlayer}); // TODO eesha
      }
    
  //  history.push(`/reflections/${roomId}/${playerNumber}`, {"members": members, "currentPlayer": currentPlayer}); // TODO eesha
   // }
  };

  if(final!=0){
  return (
    <div style={{ justifyContent: "center", alignItems: "center" }}>
      <div className="center-div">
        <h3>Here is the group consensus of the game</h3>
      </div>
      <div className="box">
        <Form>
          <Form.Label>Final Decision Of your game is: {final}</Form.Label>
        </Form>
      </div>
      <div className="center-div">
        <Button variant="primary" type="submit" onClick={(e) => handleSubmit(e)}>
          Proceed to next game
        </Button>
      </div>
    </div>
  );
  }
  else{
    return (
      <div style={{ justifyContent: "center", alignItems: "center" }}>
        <div className="center-div">
        <h3>Here is the group consensus of the game</h3>
       </div>
        <div className="box">
          <Form>
            <Form.Label>Your group does not come up with a coherent decision. This problem is not solved. </Form.Label>
          </Form>
        </div>
        <div className="center-div">
          <Button variant="primary" type="submit" onClick={(e) => handleSubmit(e)}>
            Proceed to next game
          </Button>
        </div>
      </div>
    );
  }
};

export default FinalDecision;
