import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Form, Button } from "react-bootstrap";

import "./Cognitive1.css";

const Cognitive = (props) => {
  let history = useHistory();
  const { roomId, playerNumber } = props.match.params;
  const [ validated, setValidated ] = useState(false);

  const [ q1, setQ1 ] = React.useState("");
  const [ q2, setQ2 ] = React.useState("");
  const [ q3, setQ3 ] = React.useState("");
  const [ q4, setQ4 ] = React.useState("");
  const [ q5, setQ5 ] = React.useState("");
  const [ q6, setQ6 ] = React.useState("");
  const [ q7, setQ7 ] = React.useState("");
  const [ q8, setQ8 ] = React.useState("");
  const [ q9, setQ9 ] = React.useState("");
  const [ q10, setQ10 ] = React.useState("");
  const [ q11, setQ11 ] = React.useState("");
  const [ q12, setQ12 ] = React.useState("");
  const [ q13, setQ13 ] = React.useState("");
  const [ q14, setQ14 ] = React.useState("");
  const [ q15, setQ15 ] = React.useState("");
  const [ q16, setQ16 ] = React.useState("");
  const [ q17, setQ17 ] = React.useState("");
  const [ q18, setQ18 ] = React.useState("");



  const handleSubmit = (event) => {

    event.preventDefault();
    console.log("Cognitive form Survey form submitted")
    let request = {
      "roomid": roomId,
      "player_num": "Player " + playerNumber,
      "q1": q1,
      "q2": q2,
      "q3": q3,
      "q4": q4,
      "q5": q5,
      "q6": q6,
      "q7": q7,
      "q8": q8,
      "q9": q9,
      "q10": q10,
      "q11": q11,
      "q12": q12,
      "q13": q13,
      "q14": q14,
      "q15": q15,
    };
    
    console.log(request);

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    };
    fetch('http://boardgame.cogsearch.web.illinois.edu/cognitive1', options)
      .then(response => response.json())
      .then(data => console.log(data));

    console.log(request);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {

    }
    setValidated(true);
    history.push(`/cog2instructions/${roomId}/${playerNumber}`);
  };

  return (
    <div className="demo-container">
      <h2> Conginitive Questionnaire 1</h2>

      <br />
      <h3>Please answer the following questions:</h3><br />
      <h2>Questions</h2>
      <Form className="formgrid">
        <Form.Group className="mb-3" controlId="q1">
          <Form.Label>mumble</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "block", margin: "10px" }}>
               <Form.Check value="1" label="1-speak indistinctly"  type='radio'  checked={q1 == "speak indistinctly"} onChange={e => setQ1(e.target.value)}/>
               <Form.Check value="2" label="2-complain"  type='radio'  checked={q1 == "complain"} onChange={e => setQ1(e.target.value)}/>
               <Form.Check value="3" label="3-handle awkwardly"  type='radio'  checked={q1 == "handle awkwardly"} onChange={e => setQ1(e.target.value)}/>
               <Form.Check value="4" label="4- fall  over something"  type='radio'  checked={q1 == "fall  over something"} onChange={e => setQ1(e.target.value)}/>
               <Form.Check value="5" label="5-tear apart"  type='radio'  checked={q1 == "tear apart"} onChange={e => setQ1(e.target.value)}/>
               <Form.Check value="6" label="6-I don't know"  type='radio'  checked={q1 == "I don't know"} onChange={e => setQ1(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q2">
          <Form.Label>perspire</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "block", margin: "10px" }}>
               <Form.Check value="1" label="1-struggle"  type='radio'  checked={q2 == "struggle"} onChange={e => setQ2(e.target.value)}/>
               <Form.Check value="2" label="2-sweat"  type='radio'  checked={q2 == "sweat"} onChange={e => setQ2(e.target.value)}/>
               <Form.Check value="3" label="3-happen"  type='radio'  checked={q2 == "happen"} onChange={e => setQ2(e.target.value)}/>
               <Form.Check value="4" label="4-penetrate"  type='radio'  checked={q2 == "penetrate"} onChange={e => setQ2(e.target.value)}/>
               <Form.Check value="5" label="5-submit"  type='radio'  checked={q2 == "submit"} onChange={e => setQ2(e.target.value)}/>
               <Form.Check value="6" label="6-I don't know"  type='radio'  checked={q2 == "I don't know"} onChange={e => setQ2(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q3">
          <Form.Label>gush</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "block", margin: "10px" }}>
               <Form.Check value="1" label="1-giggle"  type='radio'  checked={q3 == "giggle"} onChange={e => setQ3(e.target.value)}/>
               <Form.Check value="2" label="2-spout"  type='radio'  checked={q3 == "spout"} onChange={e => setQ3(e.target.value)}/>
               <Form.Check value="3" label="3-sprinkle"  type='radio'  checked={q3 == "sprinkle"} onChange={e => setQ3(e.target.value)}/>
               <Form.Check value="4" label="4-hurry"  type='radio'  checked={q3 == "hurry"} onChange={e => setQ3(e.target.value)}/>
               <Form.Check value="5" label="5-cry"  type='radio'  checked={q3 == "tcry"} onChange={e => setQ3(e.target.value)}/>
               <Form.Check value="6" label="6-I don't know"  type='radio'  checked={q3 == "I don't know"} onChange={e => setQ3(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q4">
          <Form.Label>massive</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "block", margin: "10px" }}>
               <Form.Check value="1" label="1-strong and muscular"  type='radio'  checked={q4 == "strong and muscular"} onChange={e => setQ4(e.target.value)}/>
               <Form.Check value="2" label="2-thickly populated"  type='radio'  checked={q4 == "cthickly populated"} onChange={e => setQ4(e.target.value)}/>
               <Form.Check value="3" label="3-ugly and awkward"  type='radio'  checked={q4 == "ugly and awkward"} onChange={e => setQ4(e.target.value)}/>
               <Form.Check value="4" label="4-huge and solid"  type='radio'  checked={q4 == "huge and solid"} onChange={e => setQ4(e.target.value)}/>
               <Form.Check value="5" label="5-everlasting"  type='radio'  checked={q4 == "everlasting"} onChange={e => setQ4(e.target.value)}/>
               <Form.Check value="6" label="6-I don't know"  type='radio'  checked={q4 == "I don't know"} onChange={e => setQ4(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q5">
          <Form.Label>feign</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "block", margin: "10px" }}>
               <Form.Check value="1" label="1-pretend"  type='radio'  checked={q5 == "pretend"} onChange={e => setQ5(e.target.value)}/>
               <Form.Check value="2" label="2-prefer"  type='radio'  checked={q5 == "prefer"} onChange={e => setQ5(e.target.value)}/>
               <Form.Check value="3" label="3-wear"  type='radio'  checked={q5 == "wear"} onChange={e => setQ5(e.target.value)}/>
               <Form.Check value="4" label="4-be cautious"  type='radio'  checked={q5 == "be cautious"} onChange={e => setQ5(e.target.value)}/>
               <Form.Check value="5" label="5-surrender"  type='radio'  checked={q5 == "surrender"} onChange={e => setQ5(e.target.value)}/>
               <Form.Check value="6" label="6-I don't know"  type='radio'  checked={q5 == "I don't know"} onChange={e => setQ5(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q6">
          <Form.Label>unwary</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "block", margin: "10px" }}>
               <Form.Check value="1" label="1-unusual"  type='radio'  checked={q6 == "unusual"} onChange={e => setQ6(e.target.value)}/>
               <Form.Check value="2" label="2-deserted"  type='radio'  checked={q6 == "deserted"} onChange={e => setQ6(e.target.value)}/>
               <Form.Check value="3" label="3-incautious"  type='radio'  checked={q6 == "incautious"} onChange={e => setQ6(e.target.value)}/>
               <Form.Check value="4" label="4-sudden"  type='radio'  checked={q6 == "sudden"} onChange={e => setQ6(e.target.value)}/>
               <Form.Check value="5" label="5-tireless"  type='radio'  checked={q6 == "tireless"} onChange={e => setQ6(e.target.value)}/>
               <Form.Check value="6" label="6-I don't know"  type='radio'  checked={q6 == "I don't know"} onChange={e => setQ6(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q7">
          <Form.Label>veer</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "block", margin: "10px" }}>
               <Form.Check value="1" label="1-change directiony"  type='radio'  checked={q7 == "change direction"} onChange={e => setQ7(e.target.value)}/>
               <Form.Check value="2" label="2-hesitate"  type='radio'  checked={q7 == "hesitate"} onChange={e => setQ7(e.target.value)}/>
               <Form.Check value="3" label="3-catch sight of"  type='radio'  checked={q7 == "catch sight of"} onChange={e => setQ7(e.target.value)}/>
               <Form.Check value="4" label="4-cover with a thin layer"  type='radio'  checked={q7 == "cover with a thin layer"} onChange={e => setQ7(e.target.value)}/>
               <Form.Check value="5" label="5-slide"  type='radio'  checked={q7 == "slide"} onChange={e => setQ7(e.target.value)}/>
               <Form.Check value="6" label="6-I don't know"  type='radio'  checked={q7 == "I don't know"} onChange={e => setQ7(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q8">
          <Form.Label>orthodox</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "block", margin: "10px" }}>
               <Form.Check value="1" label="1-conventional"  type='radio'  checked={q8 == "conventional"} onChange={e => setQ8(e.target.value)}/>
               <Form.Check value="2" label="2-straight"  type='radio'  checked={q8 == "straight"} onChange={e => setQ8(e.target.value)}/>
               <Form.Check value="3" label="3-surgical"  type='radio'  checked={q8 == "surgical"} onChange={e => setQ8(e.target.value)}/>
               <Form.Check value="4" label="4-right-angled"  type='radio'  checked={q8 == "right-angled"} onChange={e => setQ8(e.target.value)}/>
               <Form.Check value="5" label="5-religious"  type='radio'  checked={q8 == "religious"} onChange={e => setQ8(e.target.value)}/>
               <Form.Check value="6" label="6-I don't know"  type='radio'  checked={q8 == "I don't know"} onChange={e => setQ8(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q9">
          <Form.Label>stripling</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "block", margin: "10px" }}>
               <Form.Check value="1" label="1-stream"  type='radio'  checked={q9 == "stream"} onChange={e => setQ9(e.target.value)}/>
               <Form.Check value="2" label="2-narrow path"  type='radio'  checked={q9 == "narrow path"} onChange={e => setQ9(e.target.value)}/>
               <Form.Check value="3" label="3-engraving"  type='radio'  checked={q9 == "engraving"} onChange={e => setQ9(e.target.value)}/>
               <Form.Check value="4" label="4-lad"  type='radio'  checked={q9 == "lad"} onChange={e => setQ9(e.target.value)}/>
               <Form.Check value="5" label="5-tear apart"  type='radio'  checked={q9 == "tear apart"} onChange={e => setQ9(e.target.value)}/>
               <Form.Check value="6" label="6-I don't know"  type='radio'  checked={q9 == "I don't know"} onChange={e => setQ9(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q10">
          <Form.Label>salubrious</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "block", margin: "10px" }}>
               <Form.Check value="1" label="1-mirthful"  type='radio'  checked={q10 == "mirthful"} onChange={e => setQ10(e.target.value)}/>
               <Form.Check value="2" label="2-indecent"  type='radio'  checked={q10 == "indecent"} onChange={e => setQ10(e.target.value)}/>
               <Form.Check value="3" label="3-salty"  type='radio'  checked={q10 == "salty"} onChange={e => setQ10(e.target.value)}/>
               <Form.Check value="4" label="4-mournful"  type='radio'  checked={q10 == "mournful"} onChange={e => setQ10(e.target.value)}/>
               <Form.Check value="5" label="5-healthful"  type='radio'  checked={q10 == "healthful"} onChange={e => setQ10(e.target.value)}/>
               <Form.Check value="6" label="6-I don't know"  type='radio'  checked={q10 == "I don't know"} onChange={e => setQ10(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q11">
          <Form.Label>limpid</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "block", margin: "10px" }}>
               <Form.Check value="1" label="1-lazy"  type='radio'  checked={q11 == "lazy"} onChange={e => setQ11(e.target.value)}/>
               <Form.Check value="2" label="2-crippled"  type='radio'  checked={q11 == "crippled"} onChange={e => setQ11(e.target.value)}/>
               <Form.Check value="3" label="3-clear"  type='radio'  checked={q11 == "clear"} onChange={e => setQ11(e.target.value)}/>
               <Form.Check value="4" label="4-hot"  type='radio'  checked={q11 == "hot"} onChange={e => setQ11(e.target.value)}/>
               <Form.Check value="5" label="5-slippery"  type='radio'  checked={q11 == "slippery"} onChange={e => setQ11(e.target.value)}/>
               <Form.Check value="6" label="6-I don't know"  type='radio'  checked={q11 == "I don't know"} onChange={e => setQ11(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q12">
          <Form.Label>procreate</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "block", margin: "10px" }}>
               <Form.Check value="1" label="1-sketch"  type='radio'  checked={q12 == "sketch"} onChange={e => setQ12(e.target.value)}/>
               <Form.Check value="2" label="2-inhabit"  type='radio'  checked={q12 == "inhabit"} onChange={e => setQ12(e.target.value)}/>
               <Form.Check value="3" label="3-imitate"  type='radio'  checked={q12 == "imitate"} onChange={e => setQ12(e.target.value)}/>
               <Form.Check value="4" label="4-beget"  type='radio'  checked={q12 == "beget"} onChange={e => setQ12(e.target.value)}/>
               <Form.Check value="5" label="5-encourage"  type='radio'  checked={q12 == "encourage"} onChange={e => setQ12(e.target.value)}/>
               <Form.Check value="6" label="6-I don't know"  type='radio'  checked={q12 == "I don't know"} onChange={e => setQ12(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q13">
          <Form.Label>replete</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "block", margin: "10px" }}>
               <Form.Check value="1" label="1-full"  type='radio'  checked={q13 == "full"} onChange={e => setQ13(e.target.value)}/>
               <Form.Check value="2" label="2-elderly"  type='radio'  checked={q13 == "elderly"} onChange={e => setQ13(e.target.value)}/>
               <Form.Check value="3" label="3-resentful"  type='radio'  checked={q13 == "resentful"} onChange={e => setQ13(e.target.value)}/>
               <Form.Check value="4" label="4-discredited"  type='radio'  checked={q13 == "discredited"} onChange={e => setQ13(e.target.value)}/>
               <Form.Check value="5" label="5-restful"  type='radio'  checked={q13 == "restful"} onChange={e => setQ13(e.target.value)}/>
               <Form.Check value="6" label="6-I don't know"  type='radio'  checked={q13 == "I don't know"} onChange={e => setQ13(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q14">
          <Form.Label>frieze</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "block", margin: "10px" }}>
               <Form.Check value="1" label="1-fringe of curls on the forehead"  type='radio'  checked={q14 == "fringe of curls on the forehead"} onChange={e => setQ14(e.target.value)}/>
               <Form.Check value="2" label="2-statue"  type='radio'  checked={q14 == "statue"} onChange={e => setQ14(e.target.value)}/>
               <Form.Check value="3" label="3-ornamental band"  type='radio'  checked={q14 == "ornamental band"} onChange={e => setQ14(e.target.value)}/>
               <Form.Check value="4" label="4-discredited"  type='radio'  checked={q14 == "discredited"} onChange={e => setQ14(e.target.value)}/>
               <Form.Check value="5" label="5-restful"  type='radio'  checked={q14 == "restful"} onChange={e => setQ14(e.target.value)}/>
               <Form.Check value="6" label="6-I don't know"  type='radio'  checked={q14 == "I don't know"} onChange={e => setQ14(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q15">
          <Form.Label>treacle</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "block", margin: "10px" }}>
               <Form.Check value="1" label="1-sewing machine"  type='radio'  checked={q15 == "sewing machine"} onChange={e => setQ15(e.target.value)}/>
               <Form.Check value="2" label="2-framework"  type='radio'  checked={q15 == "framework"} onChange={e => setQ15(e.target.value)}/>
               <Form.Check value="3" label="3-leak"  type='radio'  checked={q15 == "leak"} onChange={e => setQ15(e.target.value)}/>
               <Form.Check value="4" label="4-apple butter"  type='radio'  checked={q15 == "fapple butter"} onChange={e => setQ15(e.target.value)}/>
               <Form.Check value="5" label="5-molasses"  type='radio'  checked={q15 == "molasses"} onChange={e => setQ15(e.target.value)}/>
               <Form.Check value="6" label="6-I don't know"  type='radio'  checked={q15 == "I don't know"} onChange={e => setQ15(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q16">
          <Form.Label>ignominious</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "block", margin: "10px" }}>
               <Form.Check value="1" label="1-inflammable"  type='radio'  checked={q16 == "inflammable"} onChange={e => setQ16(e.target.value)}/>
               <Form.Check value="2" label="2-elflike"  type='radio'  checked={q16 == "elflike"} onChange={e => setQ16(e.target.value)}/>
               <Form.Check value="3" label="3-unintelligent"  type='radio'  checked={q16 == "unintelligent"} onChange={e => setQ16(e.target.value)}/>
               <Form.Check value="4" label="4-disgraceful"  type='radio'  checked={q16 == "disgraceful"} onChange={e => setQ16(e.target.value)}/>
               <Form.Check value="5" label="5-mysterious"  type='radio'  checked={q16 == "mysterious"} onChange={e => setQ16(e.target.value)}/>
               <Form.Check value="6" label="6-I don't know"  type='radio'  checked={q16 == "I don't know"} onChange={e => setQ16(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q17">
          <Form.Label>abjure</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "block", margin: "10px" }}>
               <Form.Check value="1" label="1-make certain"  type='radio'  checked={q17 == "make certain"} onChange={e => setQ17(e.target.value)}/>
               <Form.Check value="2" label="2-arrest"  type='radio'  checked={q17 == "arrest"} onChange={e => setQ17(e.target.value)}/>
               <Form.Check value="3" label="3-renounce"  type='radio'  checked={q17 == "renounce"} onChange={e => setQ17(e.target.value)}/>
               <Form.Check value="4" label="4-abuse"  type='radio'  checked={q17 == "abuse"} onChange={e => setQ17(e.target.value)}/>
               <Form.Check value="5" label="5-lose"  type='radio'  checked={q17 == "lose"} onChange={e => setQ17(e.target.value)}/>
               <Form.Check value="6" label="6-I don't know"  type='radio'  checked={q17 == "I don't know"} onChange={e => setQ17(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q18">
          <Form.Label>duress</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "block", margin: "10px" }}>
               <Form.Check value="1" label="1-period of time"  type='radio'  checked={q18 == "period of time"} onChange={e => setQ18(e.target.value)}/>
               <Form.Check value="2" label="2-distaste"  type='radio'  checked={q18 == "distaste"} onChange={e => setQ18(e.target.value)}/>
               <Form.Check value="3" label="3-courage"  type='radio'  checked={q18 == "courage"} onChange={e => setQ18(e.target.value)}/>
               <Form.Check value="4" label="4-hardness"  type='radio'  checked={q18 == "hardness"} onChange={e => setQ18(e.target.value)}/>
               <Form.Check value="5" label="5-compulsion"  type='radio'  checked={q18 == "compulsion"} onChange={e => setQ18(e.target.value)}/>
               <Form.Check value="6" label="6-I don't know"  type='radio'  checked={q18 == "I don't know"} onChange={e => setQ18(e.target.value)}/>
            </span>
          </span>
        </Form.Group>
      </Form>
      <Button style={{marginLeft: "50%"}} variant="primary" type="submit" onClick={(e) => handleSubmit(e)}>
          Submit
        </Button>
    </div >
  );
};

export default Cognitive;
