import React,{useEffect, useState } from "react";
import { useHistory} from "react-router-dom";
import { Form, Button, Row, Card, Col } from 'react-bootstrap';
import RangeSlider from 'react-bootstrap-range-slider';
import "./SurveyPage.css";
import useChat from "../useChat";

const SurveyPage = (props) => {
  let history = useHistory();
  const { roomId, playerNumber } = props.match.params;

  let gameNum = parseInt(props.location.state.game);
  let round_1 = props.location.state.round;
  let cardMessage = props.location.state.card;
  let finalGame = props.location.state.finalGame;
  let gameEndQuestions = props.location.state.gameEndQuestions;
  let feedbackQuestions = props.location.state.feedbackQuestions;
  let members_1 = props.location.state.members;
  let currentPlayer = props.location.state.currentPlayer;
  let players = props.location.state.players;
  //let gameend1 = props.location.gameend1; //Jul5

  const { setMidGame, countofsurvey, sendSurveySubmit, messages, round, members, player_number, gameEnd, roomEnd, midGame, sendMessage } = useChat(roomId, playerNumber);

  const [questions, setquestions] = useState({q1:{a:0, b:0}, q2:{a:0, b:0}, q3:{a:0, b:0}, q4:{a:0, b:0}, q5:{a:1, b:1}, q6:{a:1, b:1}});
  const { q1,q2,q3,q4,q5,q6 } = questions;

  const [iswaiting, setShowWaitPage] = useState(false);
  //const [final, setFinalDecision] = useState("hello");
  

  //console.log(`game number: ${gameNum}`)

  // useEffect(() => {
  //   if(!countofsurvey){
  //     //setCountdownDuration(Date.now() + COUNTDOWN_DURATION); // 21M
  //     setShowWaitPage(false);
  //     console.log('Count of survey '+countofsurvey);
  //     console.log('Is waiting '+iswaiting);
  //     console.log(" not final game");

  //     const fetchFinalDecision = async () => {
  //       // console.log("Inside fetchAverage: "+card)
  //        const options = {
  //          method: 'GET',
  //          headers: {
  //            'Content-Type': 'application/json',
  //          }
  //        };
  //        //console.log("The members in the room "+members)
  //        fetch(`http://127.0.0.1:4000/getfinal?roomId=${roomId}&players=${JSON.stringify(members)}`, options)
  //        .then(response => response.json())
  //        .then(data => {
  //          //console.log("Average data of the card");
  //          console.log("The final decision is "+data.final);
  //         // setFinalDecision(data.final);
  //          history.push(`/finaldecision/${roomId}/${playerNumber}`, {"members": members_1, "currentPlayer": currentPlayer, "finalGame": finalGame, "gameNum": gameNum, "final": data.final});
           
  //          //return data;
  //        });
  //      };

  //     fetchFinalDecision();

      
      
  //   }
  // }, [countofsurvey]);
  

  useEffect(() => {
    if(!countofsurvey){
      setShowWaitPage(false);
      // console.log('Count of survey '+countofsurvey);
      // console.log('Is waiting '+iswaiting);
      // console.log(" not final game");

      const calculateHigestAvgAndgoToFinalDecisionPage = async () => {
        // console.log("Inside fetchAverage: "+card)
         const options = {
           method: 'GET',
           headers: {
             'Content-Type': 'application/json',
           }
         };       
         //console.log("The members in the room "+members)
         fetch(`http://nodeapp.cogsearch.web.illinois.edu/gethighestavg?roomId=${roomId}&players=${JSON.stringify(members)}&cards=${messages}&round=${round}`, options) //13/5
         .then(response => response.json())
         .then(data => {
           //console.log("Average data of the card");
           console.log("The higest rated card is ********"+data.card);
          // setFinalDecision(data.final);
           history.push(`/endgamesurvey/${roomId}/${playerNumber}`, {"members": members_1, "currentPlayer": currentPlayer, "finalGame": finalGame, "gameNum": gameNum, "highestavg_card": data.card, "gameEndQuestions": gameEndQuestions , "messages": cardMessage});//Jul5
           
           //return data;
         });
       };

       calculateHigestAvgAndgoToFinalDecisionPage();
       
    }
  }, [countofsurvey]);
  //const [geq1, setGEQ1] = useState(0);

    const handleSubmit = (event) => {
      const allQuestionsAnswered = Object.values(questions).every(
        (question) => question.a > 0 && question.b > 0
      );
    
      console.log("If all questions are answerd or not "+ allQuestionsAnswered)
    
       if (!allQuestionsAnswered) {
        event.preventDefault();
        event.stopPropagation();
      }
      else{
  
      let p = players;
      let ind = 1;
     // console.log("Card messages length "+p)
      let n = cardMessage.length;
      let request = {}
      while(ind<players+1) {
        
          let question = `q${ind}`;
          let a = questions[question].a;
          let b = questions[question].b;
          console.log(`Question ${question} - a: ${a}, b: ${b}`);
        
        request = {
          "roomid": roomId,
          "game_num": gameNum,
          "round_num": round, //15/5
          "player_num": "Player " + playerNumber,
          "card": cardMessage[n-1].body.substring(0, 7),
          "q1_res": a,
          "q2_res": b,
          "game_end": false
        };
  
        const options = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(request),
        };
        fetch('http://boardgame.cogsearch.web.illinois.edu/survey', options)
          .then(response => response.json())
          .then(data => console.log(data));
  
        console.log(request);
        ind=ind+1;
        n = n-1;
      }
  
      sendSurveySubmit(true);
      setShowWaitPage(true);
    }
        
    };

    //const formGroups = [];
    const formGroups = [];
    let n = players;
    //console.log("Value of n "+n);
    let z = cardMessage.length;
    let ctrl = 0;
    
      let flag = 0;
      
      formGroups.push(
       
        Object.keys(questions).map((questionKey) => {
          if(flag==n){
            return;
          }
          flag+=1;
          z = z-1;
         // console.log("The card now "+ cardMessage[z].body.substring(0, 7));
        return (
            <div key={questionKey}>
            <Row>
            <Card body style={{ width: '250px', left: '25%', height: '310px', backgroundColor: '#31a24c', color: "white", fontSize: '14px' }}>{cardMessage[z].body.substring(0, 7)}<br></br>{cardMessage[z].description}</Card>
            </Row>
           <Form.Label>Please enter your opinion about the card above</Form.Label>
             <Form.Group className="mb-3" controlId={"q"+(ctrl+1)}>
                  <Form.Label>{feedbackQuestions[0]}</Form.Label><br />
                  <span style={{ display: "inline-block" }}>
                      <span style={{ display: "inline-block" }}>0</span>
                      <span style={{ display: "inline-block", margin: "10px" }}>
                          <RangeSlider
                              value={questions[questionKey].a}
                              onChange = {(e) =>
                                      setquestions({
                                        ...questions,
                                        [questionKey]: {
                                          ...questions[questionKey],
                                          a: e.target.value,
                                        },
                                      })} />
                      </span>
                      <span style={{ display: "inline-block" }}>100</span>
                  </span>
             </Form.Group>
        <Form.Group className="mb-3" controlId={"q"+(ctrl+2)}>
                  <Form.Label>{feedbackQuestions[1]}</Form.Label><br />
                  <span style={{ display: "inline-block" }}>
                      <span style={{ display: "inline-block" }}>0</span>
                      <span style={{ display: "inline-block", margin: "10px" }}>
                          <RangeSlider
                             value={questions[questionKey].b}
                             onChange = {(e) =>
                                    setquestions({
                                      ...questions,
                                      [questionKey]: {
                                        ...questions[questionKey],
                                        b: e.target.value,
                                      },
                                    })} />
                      </span>
                      <span style={{ display: "inline-block" }}>100</span>
                  </span>
              </Form.Group>
               </div>
        )
      })
      );
      n=n-1;
      ctrl = ctrl + 1;

      if(countofsurvey && iswaiting){
   
        // console.log("Is waiting is set to true for this player")
        // setShowWaitPage(false); // 21March
        // console.log("Now Is waiting is set to "+ iswaiting)
        return (
          <div className="member-lobby">
            <h2>Waiting for more players...</h2>
            <Col >
              <Row><h3 className="title">Please wait till the other players finish.</h3></Row>
              <Row>
                <Card style={{ width: '400px', zIndex: -1 }}>
                </Card>
              </Row>
            </Col>
          </div>
        );
    
       }

 else{

  return (
    <div style={{ justifyContent: "center", alignItems: "center" }}>
      <div className="center-div">
        <h3>Please share you opinion on the last round and the game</h3>
      </div>
      <div className="box">
        <Form>
        {formGroups}
        <div className="center-div">
        <Button variant="primary" type="submit" onClick={(e) => handleSubmit(e)}>
          Submit Responses
        </Button>
      </div>
        </Form>
      </div>
    </div>
  );

 }
};

export default SurveyPage;
