import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const Game = (props) => {
    // alert("Eesha inside Game component")

    const [show, setShow] = useState(true);

  const handleClose = () => {
    setShow(false)
    props.handleisClosed()
};
 // const handleShow = () => setShow(true);

    return (
<>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Scenario Description:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <i style={{ fontSize: "13px" }}>{props.description}</i>
        {/* <i style={{ fontSize: "13px" }}><div dangerouslySetInnerHTML={{ __html: props.description }} /></i> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
      );
  };

export default Game;
