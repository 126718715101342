import React, {useState} from "react";
import { useHistory } from "react-router-dom";
import { Form, Button } from "react-bootstrap";

import "./Demographic.css";

const Demographic = (props) => {
  let history = useHistory();
  const { roomId, playerNumber } = props.match.params;
  const [ validated, setValidated ] = useState(false);

  const [dob, setDob] = useState();
  const [age, setAge] = useState(0);
  const [education, setEducation] = useState(0);
  const [gender, setGender] = useState(null);
  const [nativespeaker, setNativeSpeaker] = useState("1");
  const [firstLan, setFirstLan] = useState("");
  const [engAcqAge, setEngAcqAge] = useState(0);
  const [pId, setProlificID] = useState("temp");
  //const [hispOrLatino, setHispOrLatino] = useState("2");
  const [ethnicity, setEthnicity] = useState(null);
  const [ q1, setQ1 ] = React.useState(null);
  const [ q2, setQ2 ] = React.useState(null);
  const [ q3, setQ3 ] = React.useState(null);
  const [ q4, setQ4 ] = React.useState(null);
  const [ q5, setQ5 ] = React.useState(null);
  const [ q6, setQ6 ] = React.useState(null);
  const [ q7, setQ7 ] = React.useState(null);
  const [ q8, setQ8 ] = React.useState(null);
  const [ q9, setQ9 ] = React.useState(null);
  const [ q10, setQ10 ] = React.useState(null);
  const [formError, setFormError] = React.useState(false);


  const handleDobChange = (event) => {
    setDob(event.target.value);
  };
  const handleAgeChange = (event) => {
    setAge(event.target.value);
  };

  const handleEducationChange = (event) => {
    setEducation(event.target.value);
  };

  const handleNativeSpeakerChange = (event) => {
    setNativeSpeaker(event.target.value);
  };
  const handleFirstLanChange = (event) => {
    setFirstLan(event.target.value);
  };
  const handleEngAcqAgeChange = (event) => {
    setEngAcqAge(event.target.value);
  };

  const handleProlificIdChange = (event) => {
    setProlificID(event.target.value);
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false || q1 === null || q2 === null || q3 === null || q4 === null || q5 === null || q6 === null || q7 === null || q8 === null || q9 === null || q10 ===null) {
      event.preventDefault();
      event.stopPropagation();

      const warningMessage = document.getElementById('warningMessage');
      warningMessage.textContent = 'Please complete all questions before submitting.';
    } else {
      let ethn = ethnicity;

      console.log("Prolific ID "+pId);

      let request = {
        "roomid": roomId,
        "player_num":  "Player " + playerNumber,
        "dob": dob,
        "age": parseInt(age),
        "education" : parseInt(education),
        "gender": gender,
        "native_eng": parseInt(nativespeaker),
        "first_lan": firstLan,
        "eng_acq_age": parseInt(engAcqAge),
        "ethnicity": ethn,
        "q1":q1,
        "q2":q2,
        "q3":q3,
        "q4":q4,
        "q5":q5,
        "q6":q6,
        "q7":q7,
        "q8":q8,
        "q9":q9,
        "q10":q10,
        "prolific_id" : pId
      };

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(request),
      };
      fetch('http://boardgame.cogsearch.web.illinois.edu/demographic', options)
        .then(response => response.json())
        .then(data => console.log(data));

      console.log(request);
      history.push(`/instructions/${roomId}/${playerNumber}?game=1`);
    }

    setValidated(true);
  };

  return (
    <div className="demo-container">
      <h2> Demographic information </h2>

      <br/>
      <h4>Please answer the following questions:</h4>
      <Form onSubmit={handleSubmit}>

        <Form.Group className="mb-3" controlId="Form.ControlInput2">
          <Form.Label>My Prolific ID: </Form.Label>
          <Form.Control type="text" name="prolific_id" onChange={handleProlificIdChange} required />
        </Form.Group>

        <Form.Group className="mb-3" controlId="Form.ControlInput1">
          <Form.Label>What is your date of birth? </Form.Label>
          <Form.Control type="date" name='date_of_birth'  onChange={handleDobChange} required/>
        </Form.Group>

        <Form.Group className="mb-3" controlId="Form.ControlInput2">
          <Form.Label>What is your age? </Form.Label>
          <Form.Control type="number" name='age' onChange={handleAgeChange} required />
        </Form.Group>

        <Form.Group className="mb-3" controlId="Form.ControlInput2">
          <Form.Label>How many years of education did you complete? </Form.Label>
          <Form.Control type="number" name='education' onChange={handleEducationChange} required />
        </Form.Group>

        <Form.Group className="mb-3" controlId="Form.ControlInput3">
          <Form.Label>What's your gender? </Form.Label><br></br>
          <div style={{ display: "block", margin: "10px" }}>
          <div><Form.Check inline id="gender-female" value="female" label="Female"  type='radio'  checked={gender == "female"} onChange={e => setGender(e.target.value)} required /></div>
          <div><Form.Check inline id="gender-male" value="male" label="Male"  type='radio'  checked={gender == "male"} onChange={e => setGender(e.target.value)} /></div>
          <div><Form.Check inline id="gender-other" value="other" label="Other"  type='radio'  checked={gender == "other"} onChange={e => setGender(e.target.value)} /></div>
          <div><Form.Check inline id="gender-prefer" value="prefer not to say" label="Prefer not to say"  type='radio'  checked={gender == "prefer not to say"} onChange={e => setGender(e.target.value)} /></div>
          </div>
        </Form.Group>

        <Form.Group className="mb-3" controlId="Form.ControlInput3">
          <Form.Label>Are you a native english speaker? </Form.Label>
          <Form.Select aria-label="English select" onChange={handleNativeSpeakerChange} value={nativespeaker} required>
            <option value="1">Yes</option>
            <option value="2">No</option>
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3" controlId="Form.ControlInput2">
          <Form.Label>What is your native language? </Form.Label>
          <Form.Control type="text" name='first_lan'  onChange={handleFirstLanChange} required/>
        </Form.Group>

        <Form.Group className="mb-3" controlId="Form.ControlInput2">
          <Form.Label>How old were you when you started learning English? </Form.Label>
          <Form.Control type="number" name='age_eng' onChange={handleEngAcqAgeChange} required/>
        </Form.Group>


        <Form.Group className="mb-3" controlId="Form.ControlInput3">
          <Form.Label>Please check one of the following ethnic or racial categories that best describe you: </Form.Label>
          <div style={{ display: "block", margin: "10px" }}>
          <div><Form.Check inline value="Hispanic or Latino" label="Hispanic or Latino"  type='radio'  checked={ethnicity == "Hispanic or Latino"} onChange={e => setEthnicity(e.target.value)} required /></div>
          <div>  <Form.Check inline value="Native American/Alaska Native" label="Native American/Alaska Native"  type='radio'  checked={ethnicity == "Native American/Alaska Native"} onChange={e => setEthnicity(e.target.value)} /></div>
          <div>   <Form.Check inline value="Asian" label="Asian"  type='radio'  checked={ethnicity == "Asian"} onChange={e => setEthnicity(e.target.value)} /></div>
          <div>   <Form.Check inline value="Native Hawaiian or Other Pacific Islander" label="Native Hawaiian or Other Pacific Islander"  type='radio'  checked={ethnicity == "Native Hawaiian or Other Pacific Islander"} onChange={e => setEthnicity(e.target.value)} /></div>
          <div>  <Form.Check inline value="Black or African American" label="Black or African American"  type='radio'  checked={ethnicity == "Black or African American"} onChange={e => setEthnicity(e.target.value)} /></div>
          <div> <Form.Check inline value="White or Caucasian" label="White or Caucasian"  type='radio'  checked={ethnicity == "White or Caucasian"} onChange={e => setEthnicity(e.target.value)} /></div>
          <div>   <Form.Check inline value="Other" label="Other"  type='radio'  checked={ethnicity == "Other"} onChange={e => setEthnicity(e.target.value)} /></div>
          </div>
        </Form.Group>

        
        <Form.Group className="mb-3">
          <Form.Label>How well do the following statements describe your personality?</Form.Label><br/>
          <Form.Label>I see myself as someone who</Form.Label><br/>
          
          <li>
          <Form.Label>is reserved</Form.Label><br/>
          <span style={{display: "inline"}}>
          <span style={{ display: "inline", margin: "10px" }}>
               <Form.Check inline value="1" label="Disagree strongly"  type='radio'  checked={q1 == "1"} onChange={e => setQ1(e.target.value)} required />
               <Form.Check inline value="2" label="Disagree a little"  type='radio'  checked={q1 == "2"} onChange={e => setQ1(e.target.value)} />
               <Form.Check inline value="3" label="Neither agree nor disagree"  type='radio'  checked={q1 == "3"} onChange={e => setQ1(e.target.value)} />
               <Form.Check inline value="4" label="Agree a little"  type='radio'  checked={q1 == "4"} onChange={e => setQ1(e.target.value)} />
               <Form.Check inline value="5" label="Agree strongly"  type='radio'  checked={q1 == "5"} onChange={e => setQ1(e.target.value)} />
            </span>
          </span><br/><br/></li>
          <li>
          <Form.Label>is generally trusting</Form.Label><br/>
          <span style={{display: "inline"}}>
          <span style={{ display: "inline", margin: "10px" }}>
               <Form.Check inline value="1" label="Disagree strongly"  type='radio'  checked={q2 == "1"} onChange={e => setQ2(e.target.value)} required/>
               <Form.Check inline value="2" label="Disagree a little"  type='radio'  checked={q2 == "2"} onChange={e => setQ2(e.target.value)}/>
               <Form.Check inline value="3" label="Neither agree nor disagree"  type='radio'  checked={q2 == "3"} onChange={e => setQ2(e.target.value)}/>
               <Form.Check inline value="4" label="Agree a little"  type='radio'  checked={q2 == "4"} onChange={e => setQ2(e.target.value)}/>
               <Form.Check inline value="5" label="Agree strongly"  type='radio'  checked={q2 == "5"} onChange={e => setQ2(e.target.value)}/>
            </span>
          </span><br/><br/></li>
          <li>
          <Form.Label>tends to be lazy</Form.Label><br/>
          <span style={{display: "inline"}}>
          <span style={{ display: "inline", margin: "10px" }}>
               <Form.Check inline value="1" label="Disagree strongly"  type='radio'  checked={q3 == "1"} onChange={e => setQ3(e.target.value)} required/>
               <Form.Check inline value="2" label="Disagree a little"  type='radio'  checked={q3 == "2"} onChange={e => setQ3(e.target.value)}/>
               <Form.Check inline value="3" label="Neither agree nor disagree"  type='radio'  checked={q3 == "3"} onChange={e => setQ3(e.target.value)}/>
               <Form.Check inline value="4" label="Agree a little"  type='radio'  checked={q3 == "4"} onChange={e => setQ3(e.target.value)}/>
               <Form.Check inline value="5" label="Agree strongly"  type='radio'  checked={q3 == "5"} onChange={e => setQ3(e.target.value)}/>
            </span>
          </span><br/><br/></li>
          <li>
          <Form.Label>is relaxed, handles stress well</Form.Label><br/>
          <span style={{display: "inline"}}>
          <span style={{ display: "inline", margin: "10px" }}>
               <Form.Check inline value="1" label="Disagree strongly"  type='radio'  checked={q4 == "1"} onChange={e => setQ4(e.target.value)} required/>
               <Form.Check inline value="2" label="Disagree a little"  type='radio'  checked={q4 == "2"} onChange={e => setQ4(e.target.value)}/>
               <Form.Check inline value="3" label="Neither agree nor disagree"  type='radio'  checked={q4 == "3"} onChange={e => setQ4(e.target.value)}/>
               <Form.Check inline value="4" label="Agree a little"  type='radio'  checked={q4 == "4"} onChange={e => setQ4(e.target.value)}/>
               <Form.Check inline value="5" label="Agree strongly"  type='radio'  checked={q4 == "5"} onChange={e => setQ4(e.target.value)}/>
            </span>
          </span><br/><br/></li>
          <li>
          <Form.Label>has few artistic interests</Form.Label><br/>
          <span style={{display: "inline"}}>
          <span style={{ display: "inline", margin: "10px" }}>
               <Form.Check inline value="1" label="Disagree strongly"  type='radio'  checked={q5 == "1"} onChange={e => setQ5(e.target.value)} required/>
               <Form.Check inline value="2" label="Disagree a little"  type='radio'  checked={q5 == "2"} onChange={e => setQ5(e.target.value)}/>
               <Form.Check inline value="3" label="Neither agree nor disagree"  type='radio'  checked={q5 == "3"} onChange={e => setQ5(e.target.value)}/>
               <Form.Check inline value="4" label="Agree a little"  type='radio'  checked={q5 == "4"} onChange={e => setQ5(e.target.value)}/>
               <Form.Check inline value="5" label="Agree strongly"  type='radio'  checked={q5 == "5"} onChange={e => setQ5(e.target.value)}/>
            </span>
          </span><br/><br/></li>
          <li>
          <Form.Label>is outgoing, sociable</Form.Label><br/>
          <span style={{display: "inline"}}>
          <span style={{ display: "inline", margin: "10px" }}>
               <Form.Check inline value="1" label="Disagree strongly"  type='radio'  checked={q6 == "1"} onChange={e => setQ6(e.target.value)} required/>
               <Form.Check inline value="2" label="Disagree a little"  type='radio'  checked={q6 == "2"} onChange={e => setQ6(e.target.value)}/>
               <Form.Check inline value="3" label="Neither agree nor disagree"  type='radio'  checked={q6 == "3"} onChange={e => setQ6(e.target.value)}/>
               <Form.Check inline value="4" label="Agree a little"  type='radio'  checked={q6 == "4"} onChange={e => setQ6(e.target.value)}/>
               <Form.Check inline value="5" label="Agree strongly"  type='radio'  checked={q6 == "5"} onChange={e => setQ6(e.target.value)}/>
            </span>
          </span><br/><br/></li>
          <li>
          <Form.Label>tends to find fault with others</Form.Label><br/>
          <span style={{display: "inline"}}>
          <span style={{ display: "inline", margin: "10px" }}>
               <Form.Check inline value="1" label="Disagree strongly"  type='radio'  checked={q7 == "1"} onChange={e => setQ7(e.target.value)} required/>
               <Form.Check inline value="2" label="Disagree a little"  type='radio'  checked={q7 == "2"} onChange={e => setQ7(e.target.value)}/>
               <Form.Check inline value="3" label="Neither agree nor disagree"  type='radio'  checked={q7 == "3"} onChange={e => setQ7(e.target.value)}/>
               <Form.Check inline value="4" label="Agree a little"  type='radio'  checked={q7 == "4"} onChange={e => setQ7(e.target.value)}/>
               <Form.Check inline value="5" label="Agree strongly"  type='radio'  checked={q7 == "5"} onChange={e => setQ7(e.target.value)}/>
            </span>
          </span><br/><br/></li>
          <li>
          <Form.Label>does a thorough job</Form.Label><br/>
          <span style={{display: "inline"}}>
          <span style={{ display: "inline", margin: "10px" }}>
               <Form.Check inline value="1" label="Disagree strongly"  type='radio'  checked={q8 == "1"} onChange={e => setQ8(e.target.value)} required/>
               <Form.Check inline value="2" label="Disagree a little"  type='radio'  checked={q8 == "2"} onChange={e => setQ8(e.target.value)}/>
               <Form.Check inline value="3" label="Neither agree nor disagree"  type='radio'  checked={q8 == "3"} onChange={e => setQ8(e.target.value)}/>
               <Form.Check inline value="4" label="Agree a little"  type='radio'  checked={q8 == "4"} onChange={e => setQ8(e.target.value)}/>
               <Form.Check inline value="5" label="Agree strongly"  type='radio'  checked={q8 == "5"} onChange={e => setQ8(e.target.value)}/>
            </span>
          </span><br/><br/></li>
          <li>
          <Form.Label>gets nervous easily</Form.Label><br/>
          <span style={{display: "inline"}}>
          <span style={{ display: "inline", margin: "10px" }}>
               <Form.Check inline value="1" label="Disagree strongly"  type='radio'  checked={q9 == "1"} onChange={e => setQ9(e.target.value)} required/>
               <Form.Check inline value="2" label="Disagree a little"  type='radio'  checked={q9 == "2"} onChange={e => setQ9(e.target.value)}/>
               <Form.Check inline value="3" label="Neither agree nor disagree"  type='radio'  checked={q9 == "3"} onChange={e => setQ9(e.target.value)}/>
               <Form.Check inline value="4" label="Agree a little"  type='radio'  checked={q9 == "4"} onChange={e => setQ9(e.target.value)}/>
               <Form.Check inline value="5" label="Agree strongly"  type='radio'  checked={q9 == "5"} onChange={e => setQ9(e.target.value)}/>
            </span>
          </span><br/><br/></li>
          <li>
          <Form.Label>has an active imagination</Form.Label><br/>
          <span style={{display: "inline"}}>
          <span style={{ display: "inline", margin: "10px" }}>
               <Form.Check inline value="1" label="Disagree strongly"  type='radio'  checked={q10 == "1"} onChange={e => setQ10(e.target.value)} required/>
               <Form.Check inline value="2" label="Disagree a little"  type='radio'  checked={q10 == "2"} onChange={e => setQ10(e.target.value)}/>
               <Form.Check inline value="3" label="Neither agree nor disagree"  type='radio'  checked={q10 == "3"} onChange={e => setQ10(e.target.value)}/>
               <Form.Check inline value="4" label="Agree a little"  type='radio'  checked={q10 == "4"} onChange={e => setQ10(e.target.value)}/>
               <Form.Check inline value="5" label="Agree strongly"  type='radio'  checked={q10 == "5"} onChange={e => setQ10(e.target.value)}/>
            </span>
          </span><br/><br/></li>
          {formError && <span style={{ color: "red" }}>Please select an option for all the questions.</span>}
        </Form.Group>
        <div id="warningMessage" style={{ color: "red" }}></div>
        <br/>
        <Button type="submit">Submit form</Button>


      </Form>
    </div>
  );
};

export default Demographic;
