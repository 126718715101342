import React from "react";

import "./Card.css";

const Card = (props) => {

  const { owner, body, average, description } = props; // 18A
  // if (average == null){
  //   average = 0;
  // }
  return (
    <li
      className={`message-item-square ${owner ? "my-message" : "received-message"
        }`}
    >
      {body}
      {/* {console.log("The value of average is "+average)} */}
      {average !== undefined && <h3 className="average-text">Average: {average}</h3>}
      {/* <h3 className="average-text">Average: {average}</h3> */}
      <div className="card-description">{description}</div>
    </li>
  );
};

export default Card;
