import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Card as BootstrapCard } from 'react-bootstrap';
import Countdown, { zeroPad, CountdownApi } from "react-countdown";

import useChat from "../useChat";
import Card from "../Card/Card.jsx";
import Survey from "../Survey/Survey";
import Game from '../GameDescription/Game';

import MidGameSurvey from "../MidGameSurvey/MidGameSurvey"; 

import "./Room.css";


const DEFAULT_CONFIG = {
  PLAYER_LIMIT_PER_ROOM: 4,
  ROUND_LIMIT: 2,
  GAME_LIMIT: 1,
  COUNTDOWN_DURATION: 180000,
  DISPLAY_SURVEY_DELAY: 5000
};
const COUNTDOWN_DURATION = 180000; // milliseconds
const countdownRenderer = ({ hours, minutes, seconds, completed }) => {
  return (
    <span>
      {zeroPad(minutes)}:{zeroPad(seconds)}
    </span>
  );
};

const Room = (props) => {
  let history = useHistory();

  let gameText = window.location.href.match(/game=\d/)[0];
  const gameNum = gameText.match(/\d/)[0];

  const { roomId, playerNumber } = props.match.params;
  const { setMidGame, countofsurvey, sendSurveySubmit, messages, round, members, player_number, gameEnd, roomEnd, midGame, sendMessage } = useChat(roomId, playerNumber); //yolo
  const [ cards, setCards ] = useState([]);
  const [ midGameQuestions, setMidGameQuestions ] = useState([]); 
  const [ feedbackQuestions, setFeedbackQuestions ] = useState([]);
  const [ gameEndQuestions, setGameEndQuestions ] = useState([]);
  const [ gameDescription, setGameDescription ] = useState(""); // changes the state from object to string
  const [showModal, setShowModal] = useState(true); 
  const [isClosed, setisClosed] = useState(false);
  const [isTextHidden, setTextHidden] = useState(true);
  const [ismidgame, setShowMidgame] = useState(false);
  const [iswaiting, setShowWaitPage] = useState(false);
  const [average, setAverage] = useState(0);
  const [highestcard, setHighest] = useState(0);
  const [feedbacktype, setFeedBackType] = useState(0);
  const [disabledButtons, setDisabledButtons] = useState([]);

  //const [gameend1, setgameEnd1] = useState(0); //Jul5

  const onClick = () => {
    setTextHidden(!isTextHidden);
  };

  const handleisClosed = () => {
    setisClosed(true);
    setShowModal(false);
  };
 // 21M

  // const handleClose = () => {
  //   setShowModal(false);
  // };

  const [ CONFIG_MAP, setConfigMap ] = useState(null);
  let configMap = CONFIG_MAP ? CONFIG_MAP: DEFAULT_CONFIG;
  const [ prevRound, setPrevRound ] = useState(1);
  const [ countdownDuration, setCountdownDuration ] = useState(null);
  const [ resetCountdown, setResetCountdown ] = useState(false);
  
 

  useEffect(() => {
    if(player_number !== "Unknown") {
      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      };
      console.log(`config map: ${CONFIG_MAP}`)
      if (CONFIG_MAP == null) { 
        fetch(`http://nodeapp.cogsearch.web.illinois.edu/getconfig?roomId=${roomId}&playerNum=${playerNumber}`, options)
          .then(response => response.json())
          .then(data => {
            console.log("Fetch data result");
            console.log(data);
            return data;
          })
          .then(data => {
            setConfigMap(data.config);
            setFeedBackType(configMap.FEEDBACK_TYPE);
            setCards(data.cards);
            setFeedbackQuestions(data.feedback_questions);
            setGameEndQuestions(data.game_end_questions);
            setGameDescription(data.game_description);
            setMidGameQuestions(data.midgame_questions); 
          });
      }
    }
  }, [roomId, CONFIG_MAP, playerNumber, player_number]);


  useEffect(() => {
    if(!countofsurvey){
      setCountdownDuration(Date.now() + COUNTDOWN_DURATION); // 21M
      setShowWaitPage(false);
      //console.log('Count of survey '+countofsurvey);
      //console.log('Is waiting '+iswaiting);

      const fetchHighestAverage = async () => {
        // console.log("Inside fetchAverage: "+card)
         const options = {
           method: 'GET',
           headers: {
             'Content-Type': 'application/json',
           }
         };
         //console.log("The members in the room "+members)
         fetch(`http://nodeapp.cogsearch.web.illinois.edu/gethighestavg?roomId=${roomId}&players=${JSON.stringify(members)}&cards=${messages}&round=${round}`, options) //22/5
           .then(response => response.json())
           .then(data => {
             //console.log("Average data of the card");
            // console.log("The higest rated card is in Room ********"+data.card);
            // setFinalDecision(data.final);
             //history.push(`/endgamesurvey/${roomId}/${playerNumber}`, {"members": members_1, "currentPlayer": currentPlayer, "finalGame": finalGame, "gameNum": gameNum, "highestavg_card": data.card, "gameEndQuestions": gameEndQuestions});
             setHighest(data.card);
             //return data;
           });
       };
       if(members.length>0){
       fetchHighestAverage();
       }
    }

    
  }, [countofsurvey]);

  useEffect(() => {

   // console.log("Feedback type vaue "+ configMap.FEEDBACK_TYPE)
   // console.log("Room.jsx Inside useeffect for messages");
    const fetchAverage = async (card) => {
     // console.log("Inside fetchAverage: "+card)
      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      };
      //console.log("The members in the room "+members)
      fetch(`http://nodeapp.cogsearch.web.illinois.edu/getaverage?roomId=${roomId}&card=${card}&players=${JSON.stringify(members)}`, options)
      .then(response => response.json())
      .then(data => {
        //console.log("Average data of the card");
       // console.log(data);
        setAverage(prevState => ({
          ...prevState,
          [card]: data.avg // update the average object with the new value
        }));
        //return data;
      });
    };
    messages.forEach(message => {
      const card = message.body.substring(5, 7).trim();
     // console.log("Room.jsx The card is: "+message.body.substring(5, 7).trim())
      fetchAverage(card); // fetch average value for each card
    });

 
    
  }, [messages]); 

  
  let countdownApi = null;
  const setCountdownRef = (countdown) => {
    if (countdown) {
      countdownApi = countdown.getApi();
    }
  };

  //if time runs out play the first card
  const handleCountdownEnd = () => {
    console.log('Sending default message');
    handleSendMessage(cards[0].name,  cards[0].description);
  };

  const setPrevroundAndResetTimer = (round) =>  { 
    setCountdownDuration(Date.now() + COUNTDOWN_DURATION);
    setPrevRound(round);
  }

  const handleSendMessage = (cardName, cardDescription) => {
    if(countdownApi) {
      countdownApi.pause();
    }
    const timeSpent = parseInt((COUNTDOWN_DURATION - countdownDuration + Date.now()) / 1000);
    let request = {
      "roomid": roomId,
      "game_num": gameNum,
      "round_num": round,
      "player_num": "Player " + playerNumber,
      "card_selected": cardName,
      "time_spent": timeSpent
    };

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    };
    
    fetch('http://boardgame.cogsearch.web.illinois.edu/moves', options)
      .then(response => response.json())
      .then(data => console.log(data));

      sendMessage(cardName + " was shared", round, cardDescription);
  };
  
  const handleCardClick = (card) => {
    handleSendMessage(card.name, card.description);
    setDisabledButtons(prevState => [...prevState, card.name]);
  };

  const goToSurveyPage = () => {
    const message = messages[messages.length - 1].body.substring(0, 7); // 27M
    return (
      <div className="popup-box">
        <Survey setMidGame= {setMidGame} sendSurveySubmit={sendSurveySubmit} setShowWaitPage={setShowWaitPage} countofsurvey={countofsurvey} setShowMidgame = {setShowMidgame} setPrevRound={setPrevRound} round={round} cardMessage={messages} roomId={roomId} playerNumber={playerNumber} gameNum = {gameNum} feedbackQuestions={feedbackQuestions} displayDelay={configMap.DISPLAY_SURVEY_DELAY} midgame = {midGame} players = {configMap.PLAYER_LIMIT_PER_ROOM} /> 
      </div>
    );
  };

  
  const goToMidAGameSurveyPage = () => {
    console.log("Now the midgame page should be loaded")
    return (
      <div className="popup-box"> 
        <MidGameSurvey setShowMidgame = {setShowMidgame} setPrevroundAndResetTimer={setPrevroundAndResetTimer} round={round} roomId={roomId} playerNumber={playerNumber} gameNum = {gameNum} midGameQuestions={midGameQuestions} displayDelay={configMap.DISPLAY_SURVEY_DELAY}/>
      </div>
    );
  };

  if (roomEnd) {
    console.log(' endRound');
    //console.log('roomEnd');
    const message = messages[messages.length - 1].body.substring(0, 6);
    history.push(`/surveypage/${roomId}/${playerNumber}/?game=${gameNum}`, {"game": gameNum, "card": messages, "round": round, finalGame: true, "feedbackQuestions": feedbackQuestions, "midGameQuestions": midGameQuestions, "gameEndQuestions": gameEndQuestions, "members": members, "currentPlayer": playerNumber, "players" : configMap.PLAYER_LIMIT_PER_ROOM});
  }

  if (gameEnd) {
    console.log(' endGame');
    //console.log('gameEnd');
    const message = messages[messages.length - 1].body.substring(0, 6);
    history.push(`/surveypage/${roomId}/${playerNumber}/?game=${gameNum}`, {"game": gameNum, "card": messages, "round": round, finalGame: false, "feedbackQuestions": feedbackQuestions, "midGameQuestions": midGameQuestions, "gameEndQuestions": gameEndQuestions, "members": members, "currentPlayer": playerNumber, "players" : configMap.PLAYER_LIMIT_PER_ROOM});//Jul5
  }


  let renderContent = (<div></div>);

  if (members.length < configMap.PLAYER_LIMIT_PER_ROOM) {
    
    renderContent = (
      <div className="member-lobby">
        <h2>Waiting for more players...</h2>
        <Col >
          <Row><h3 className="title">Members:</h3></Row>
          <Row>
            <BootstrapCard style={{ width: '400px', zIndex: -1 }}>
              <ul style={{padding: '10px'}}>
                {members.map((member, i) => (
                  <li>{'Player' + member['number']}</li>
                ))}
              </ul>
            </BootstrapCard>
          </Row>
        </Col>
      </div>
    );
   }else if(countofsurvey && iswaiting){
   
    // console.log("Is waiting is set to true for this player")
    // setShowWaitPage(false); // 21March
    // console.log("Now Is waiting is set to "+ iswaiting)
    renderContent = (
      <div className="member-lobby">
        <h2>Waiting for more players...</h2>
        <Col >
          <Row><h3 className="title">Please wait till the other players finish.</h3></Row>
          <Row>
            <BootstrapCard style={{ width: '400px', zIndex: -1 }}>
            </BootstrapCard>
          </Row>
        </Col>
      </div>
    );

   } else {


    if(resetCountdown === false && isClosed) {
      setCountdownDuration(Date.now() + COUNTDOWN_DURATION);
      setResetCountdown(true); 
    }

    renderContent = (

    <div className="header-div">
      
    {showModal ? (
        <Game description={(gameDescription).split("<br>").map((line, index) => (
          <div key={index} style={{ marginLeft: 20, fontSize: '16px' }}>
            {line}
          </div>
       // ))} handleClose={handleClose}  handleisClosed={handleisClosed} />
        ))}  handleisClosed={handleisClosed} />
      ) : null}

      
    {/* </div> */}
  
        <Row>
          <Col>
            <h2 className="title">Room: {roomId}</h2>
            <h2 className="title">Round: {round}</h2>
            <h2 className="title">Player: {player_number}</h2>
            <h2 className="title">Game: {gameNum}</h2>
            <div>
              <h2 className="title">Time:
              { countdownDuration ?
                <Countdown ref={setCountdownRef}  date={countdownDuration} key={countdownDuration} renderer={countdownRenderer} onComplete={handleCountdownEnd}  />  : null
              }
                
              </h2>
            </div>
            <React.Fragment>
                {prevRound !== round && goToSurveyPage()}
                {ismidgame && goToMidAGameSurveyPage() } 
            </React.Fragment>

            {/* {prevRound !== round && ismidgame ? (
              <React.Fragment>
                {goToSurveyPage()}
                {goToMidAGameSurveyPage()}
              </React.Fragment>
            ) : (
              prevRound !== round ? (
                goToSurveyPage()
              ) : ismidgame ? (
                goToMidAGameSurveyPage()
              ) : null
            )} */}
            {/* {TODO : add another condition here to avoid double update of the react component} */}
            
          </Col>
          <Col>
            <Row><h3 className="title">Members:</h3></Row>
            <Row>
              <BootstrapCard style={{ width: '300px', zIndex: -1 }}>
                <ul>
                  {members.map((member, i) => (
                    <li>{'Player' + member['number']}</li>
                  ))}
                </ul>
              </BootstrapCard>
            </Row>
            <br />
            <Row><h3 className="title">The higest rated card till now is : {highestcard}</h3></Row>
            {/* <Row>
               <Card body style={{ width: '40%', left: '25%', height: '200px', backgroundColor: '#31a24c', color: "white", }}>{highestcard}</Card>
           </Row> */}
          </Col>

          

          <Col>
            <div>
            <p style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '15px' }}>Brief Instructions for the game</p>
              <ul>
                <li>Every group member will have different cards, that some are more useful than others.</li>
                <li>You will exchange cards for 6 rounds. You will need to choose a card in 3 minutes.</li>
                <li>Please do your best to select the best possible card to share with your group each time.</li>
                <li>You will have time to read and evaluate the cards shared after each round.</li>
                <li>Please check the top-rated card to learn the group opinions.</li>
                <li>You will need to fill out a mid-game survey after 3 rounds and make the final decision at the end of card exchange.</li>
                <li>The goal of the game is to reach the best group performance.</li>
              </ul>
            </div >
            <div>
            <h2 className="title">
              Game Description:  
              <button className="gamedescription-button" onClick={onClick}>{isTextHidden ? 'Show' : 'Hide'}</button>
              {!isTextHidden ? <div style={{fontSize: '15px'}}>{(gameDescription).split("<br>").map((line, index) => (
                  <div key={index} style={{ marginLeft: 20 }}>
                    {line}
                  </div>
                ))}</div> : null}
            </h2>
            </div>
          </Col>
        </Row>

        <div className="chat-room-container">
          <div className="messages-container">
          <h2>All cards shared so far</h2>
            <ol className="messages-list">
              { messages && messages.map((message, i) => (
                //<Card key={i} owner={message.ownedByCurrentUser} body={message.body} average={average[message.body.substring(5, 7).trim()]} /> //18A
                <Card
                key={i}
                owner={message.ownedByCurrentUser}
                body={message.body}
                average={configMap.FEEDBACK_TYPE == 2 ? average[message.body.substring(5, 7).trim()] : undefined} //13/06
                description={<div style={{ fontSize: '12px', width: '200px' }}>{message.description}</div>}     
              />
             ))}
            </ol>
          </div>
          <div className="user-card-view">
          <h2>Recently shared cards</h2>
            <div className="curr-round-card-holder">
              <ul className="hor-messages-list">
                {messages.slice(Math.max(messages.length - configMap.PLAYER_LIMIT_PER_ROOM, 0)).map((message, i) => ( //18A
                  <div>
                    <Card key={i} owner={message.ownedByCurrentUser} body={message.body} average={configMap.FEEDBACK_TYPE == 2 ? average[message.body.substring(5, 7).trim()] : undefined} description={<div style={{ fontSize: '12px', width: '200px' }}>{message.description}</div>}/> 
                  </div>
                ))}
              </ul>
            </div>
            <div className="card-container">
              <h2>My Cards</h2>
              {
                cards.map((card) => (
                  <button onClick={() => handleCardClick(card)} className="send-message-button" disabled={disabledButtons.includes(card.name)} style={{
                    border: disabledButtons.includes(card.name) ? '4px solid red' : 'none',
                  }}> 
                    {card.name}<br/>
                    <div style={{ fontSize: '14px', width: '200px' ,marginLeft: '15%'}}>{card.description}</div>
                  </button>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    );
  }

  return renderContent;
};

export default Room;
