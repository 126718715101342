import React, { useEffect, useState } from "react";
import { Form, Button, Row, Card } from 'react-bootstrap';
import RangeSlider from 'react-bootstrap-range-slider';
import "./Survey.css";

const Survey = (props) => {
  let { setMidGame, sendSurveySubmit, setShowWaitPage, countofsurvey, setShowMidgame, setPrevRound, round, cardMessage, roomId, playerNumber, gameNum, feedbackQuestions, displayDelay, midgame, players } = props // 27M
  const [show, setShow] = useState(false);

  const [questions, setquestions] = useState({q1:{a:0, b:0}, q2:{a:0, b:0}, q3:{a:0, b:0}, q4:{a:0, b:0}, q5:{a:1, b:1}, q6:{a:1, b:1}})
  const { q1,q2,q3,q4,q5,q6 } = questions;

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, displayDelay);
  }, [setShow, displayDelay]);

  const handleSubmit = async (event) => { 
   // console.log("Avg: Survey form submitted")
   // console.log("The card messages "+cardMessage)
   const allQuestionsAnswered = Object.values(questions).every(
    (question) => question.a > 0 && question.b > 0
  );
    event.preventDefault();

  //  console.log("Yolo: ",JSON.stringify(questions));

    let p = players;
    let ind = 1;
   // console.log("Card messages length "+p)
    let n = cardMessage.length;
    while(ind<players+1) {
      
        let question = `q${ind}`;
        let a = questions[question].a;
        let b = questions[question].b;
     //   console.log(`Question ${question} - a: ${a}, b: ${b}`);
      
      let request = {
        "roomid": roomId,
        "game_num": gameNum,
        "round_num": round,
        "player_num": "Player " + playerNumber,
        "card": cardMessage[n-1].body.substring(0, 7),
        "q1_res": a,
        "q2_res": b,
        "game_end": false
      };

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(request),
      };
      fetch('http://boardgame.cogsearch.web.illinois.edu/survey', options)
        .then(response => response.json())
        .then(data => console.log(data));

    //  console.log(request);
      ind=ind+1;
      n = n-1;
    }
      
  

    //console.log("Avg : The value for issurveysubmit ",issurveysubmit);
    console.log("Avg : The value for countofsurvey before sendSurveySubmit(true)",countofsurvey);
   
    sendSurveySubmit(true);
    setShowWaitPage(true); // set it to false for second round of exhange
    

    if(midgame){
       console.log("MG: Eesha inside if midgame");
       setShowMidgame(true);  
       setMidGame(false);
    }else{
       setPrevRound(round); //21M
     }
     
  };


  if (!show) return null;
    
    const formGroups = [];
    let n = players;
    let z = cardMessage.length;
    let ctrl = 0;
    
   // console.log("The card messages "+ JSON.stringify(cardMessage));
    //  console.log("The questions now "+ JSON.stringify(questions));
      let flag = 0;
      
      formGroups.push(
       
        Object.keys(questions).map((questionKey) => {
          if(flag==n){
            return;
          }
          flag+=1;
          z = z-1;
       //   console.log("The card now "+ cardMessage[z].body.substring(0, 7));
          return (
            <div key={questionKey}>
            <br /><br />
            <Row>
            <Card body style={{ width: '250px', left: '25%', height: '310px', backgroundColor: '#31a24c', color: "white", fontSize: '14px' }}>{cardMessage[z].body.substring(0, 7)}<br></br>{cardMessage[z].description}</Card>
            </Row>
           <Form.Label>Please enter your opinion about the card above</Form.Label>
             <Form.Group className="mb-3" controlId={"q"+(ctrl+1)}>
             <li>   <Form.Label>{feedbackQuestions[0]}</Form.Label></li><br />
                  <span style={{ display: "inline-block" }}>
                      <span style={{ display: "inline-block" }}>0</span>
                      <b><span style={{ display: "inline-block", margin: "10px" }}>
                          <RangeSlider
                              value={questions[questionKey].a}
                              onChange = {(e) =>
                                      setquestions({
                                        ...questions,
                                        [questionKey]: {
                                          ...questions[questionKey],
                                          a: Number(e.target.value),
                                        },
                                      })} />
                      </span></b>
                      <span style={{ display: "inline-block" }}>100</span>
                  </span>
             </Form.Group>
        <Form.Group className="mb-3" controlId={"q"+(ctrl+2)}>
        <li> <Form.Label>{feedbackQuestions[1]}</Form.Label></li><br />
                  <span style={{ display: "inline-block" }}>
                      <span style={{ display: "inline-block" }}>0</span>
                    <b><span style={{ display: "inline-block", margin: "10px" }}>
                          <RangeSlider
                             value={questions[questionKey].b}
                             onChange = {(e) =>
                                    setquestions({
                                      ...questions,
                                      [questionKey]: {
                                        ...questions[questionKey],
                                        b: Number(e.target.value),
                                      },
                                    })} />
                      </span> </b>
                      <span style={{ display: "inline-block" }}>100</span>
                  </span>
              </Form.Group>
               </div>
        )
      })
      );
      n=n-1;
      ctrl = ctrl + 1;

  return (
    <div className="box">
      <div className="center-div">
        <h3>Survey Questions on the cards played</h3>
      </div>
        <Form onSubmit={(e) => handleSubmit(e)}>
          <ul>
            {formGroups}
          </ul>
        <Button variant="primary" type="submit">
          Submit
        </Button>
        </Form>
    </div>
  );
};

export default Survey;
