import React, { useState } from "react";
import { Redirect } from "react-router-dom";

import "./Cog1_Instructions.css";

const Cog1_Instructions = (props) => {
  const [ moveToLobby, setMoveToLobby ] = useState(false);
  const { roomId, playerNumber } = props.match.params;

  const handleJoinButtonClick = () => {
    setMoveToLobby(true);
  };

  return (
    <div className="main-container">
      <div>
        <p style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '35px' }}>Vocabulary (Advanced Vocabulary Task) - 1</p>
        <br />
        <p>
            This is a test of your knowledge of word meanings. Look at the sample below. <br />
            One of the five numbered words has the same meaning or nearly the same meaning as the word above the numbered words.<br />
            Mark your answer by selecting an option below the word.<br />
            <br />
            Jovial <br />
        </p>
        <br />
        <ul>
            <ul>1. refreshing</ul>
            <ul>2. scare</ul>
            <ul>3. thickest</ul>
            <ul>4. wise</ul>
            <ul>5. jolly</ul>
            <ul>6. I don't know</ul>
        </ul>
        <p>
        The answer to the sample item is number 5; therefore select number 5.<br />
        <br />
        Your score will be the number marked correctly minus a fraction of the number marked incorrectly. <br />
        Therefore, it will not be to your advantage to guess unless you are able to eliminate one or more of the answer choices as wrong.<br />
        </p>
      </div>
      <div style={{margin: 'auto'}}>
        <button className="enter-room-button" onClick={handleJoinButtonClick}>
          Start Game
        </button>
      </div>
      {moveToLobby &&
        <Redirect to={`/cognitive1/${roomId}/${playerNumber}`} />
      }
    </div>
  );
};

export default Cog1_Instructions;
