import React, { useState } from "react";
import { Redirect } from "react-router-dom";

import "./Instructions.css";

const Instructions = (props) => {
  const [ moveToLobby, setMoveToLobby ] = useState(false);
  const { roomId, playerNumber } = props.match.params;

  const handleJoinButtonClick = () => {
    setMoveToLobby(true);
  };

  return (
    <div className="main-container">
       <div>
        <p style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '35px' }}>Instructions for the game</p>
        <br />
        <ul>
          <li>In this game, you will need to work with your group members to solve a problem. Each of you will be given a unique deck of cards. Each card has information to help you solve the problems. Every group member will have different information. Some cards are more useful or reliable than others. You will need to choose and exchange the information with your group members in order to make the group reach the best performance.</li>
          <li>You will exchange cards for 6 rounds. In each round, you can choose one card to share that you think it could help the group the most. You can also learn from your group members about the information they think most valuable.</li>
          <li>Noted, in each round, you will need to choose a card in 3 minutes. It is common that you may not be able to review all cards carefully under the time limit. If you cannot choose a card within 3 minutes, a card will be randomly chosen by the program on behalf of you. Please do your best to select the best possible card to share with your group each time.</li>
          <li>After all the members share the cards each round, you will have time to read and evaluate the cards shared by you and your group members. The top-rated card will be announced after each round. This represents the group opinions, suggesting the card has the most useful and reliable information.</li>
          <li>The cards shared will always be available to you on the screen. You can check the previously shared cards to update your understandings and make the best decisions.</li>
          <li>You will need to fill out a mid-game survey after 3 rounds of card exchange.  At the end of game, you will need to make the final decision based on the information you have and what you have learned from the group members. You will also rate how helpful your group members are. Remember, the goal of the game is to reach the best group performance.</li>
        </ul><br></br>
        <p>If you are ready, please press Next to enter the game.</p>
      </div >
      <div style={{margin: 'auto'}}>
        <button className="enter-room-button" onClick={handleJoinButtonClick}>
          Join Lobby
        </button>
      </div>
      {moveToLobby &&
        <Redirect to={`/room/${roomId}/${playerNumber}?game=1`} />
      }
    </div>
  );
};

export default Instructions;
