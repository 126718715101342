import React,{useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Form, Button, Row, Card, Col  } from 'react-bootstrap';
import "./EndgameSurvey.css";
import useChat from "../useChat";

const EndgameSurvey = (props) => {
  let history = useHistory();
  const { roomId, playerNumber } = props.match.params;

  let gameNum = parseInt(props.location.state.gameNum);
  //let round_1 = props.location.state.round;
  let finalGame = props.location.state.finalGame;
  let gameEndQuestions = props.location.state.gameEndQuestions;
  let members_1 = props.location.state.members;
  let card = props.location.state.highestavg_card;
  let currentPlayer = props.location.state.currentPlayer;
  let messages_1 = props.location.state.messages;
  

  //console.log(`game number: ${gameNum}`)

  const { setMidGame, countofsurvey, sendSurveySubmit, messages, round, members, player_number, gameEnd, roomEnd, midGame, sendMessage } = useChat(roomId, playerNumber);

  const [geq1, setGEQ1] = React.useState(0);
  const [ q1, setQ1 ] = React.useState(null);
  const [ q2, setQ2 ] = React.useState(null);
  const [ q3, setQ3 ] = React.useState(null);
  const [iswaiting, setShowWaitPage] = useState(false);

  const filteredMembers = members_1.filter(member => member['number'] != currentPlayer);

  useEffect(() => {
    console.log('Useeffect for count of survey is triggered '+countofsurvey);
    if(!countofsurvey){
      //setCountdownDuration(Date.now() + COUNTDOWN_DURATION); // 21M
      setShowWaitPage(false);
      console.log('Count of survey '+countofsurvey);
      console.log('Is waiting '+iswaiting);
      console.log("not final game");

      const fetchFinalDecision = async () => {
        // console.log("Inside fetchAverage: "+card)
         const options = {
           method: 'GET',
           headers: {
             'Content-Type': 'application/json',
           }
         };
         //console.log("The members in the room "+members)
         fetch(`http://nodeapp.cogsearch.web.illinois.edu/getfinal?roomId=${roomId}&players=${JSON.stringify(members_1)}`, options)
         .then(response => response.json())
         .then(data => {
           //console.log("Average data of the card");
           console.log("The final decision is "+data.final);
          // setFinalDecision(data.final);
           history.push(`/finaldecision/${roomId}/${playerNumber}`, {"members": members_1, "currentPlayer": currentPlayer, "finalGame": finalGame, "gameNum": gameNum, "final": data.final});
           
           //return data;
         });
       };

      //fetchFinalDecision();
     // history.push(`/thanks`);

    }
  }, [countofsurvey]);


  const handleSubmit = (event) => {
    if (q1 === null || q2 === null || q3 === null) {
      event.preventDefault();
      event.stopPropagation();
    }
    else{
    //event.preventDefault();
    let request = {}
    // const sendFinalDecision = (req) => {
    //   const options = {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify(req),
    //   };
    //   fetch('http://127.0.0.1:5000/finaldecision', options)
    //     .then(response => response.json())
    //     .then(data => console.log(data));
    // };

    


   request = {
      "roomid": roomId,
      "game_num": gameNum,
      "player_num": "Player " + playerNumber,
      "q1_res": geq1,
      "p1_rating" : q1,
      "p2_rating" : q2,
      "p3_rating" : q3,
    };

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    };


    fetch('http://boardgame.cogsearch.web.illinois.edu/finaldecision', options)
        .then(response => response.json())
        .then(data => console.log(data));

    //sendFinalDecision(request);

   // sendSurveySubmit(true);
  //  setShowWaitPage(true);

      history.push(`/thanks`);
    //console.log('Count of survey '+countofsurvey);
      console.log('After setShowWaitPage waiting '+iswaiting);
       }
    // console.log(request);
    // if (!finalGame) {
    //   console.log("not final game");
    //   history.push(`/room/${roomId}/${playerNumber}?game=${gameNum + 1}`);
    // } else {
    //   console.log(" final game "+gameNum);
    //   history.push(`/reflections/${roomId}/${playerNumber}`, {"members": members, "currentPlayer": currentPlayer}); // TODO 
    // }
  };


  if(countofsurvey && iswaiting){
   
    // console.log("Is waiting is set to true for this player")
    // setShowWaitPage(false); // 21March
    // console.log("Now Is waiting is set to "+ iswaiting)
    return (
      <div className="member-lobby">
        <h2>Waiting for more players...</h2>
        <Col >
          <Row><h3 className="title">Please wait till the other players finish.</h3></Row>
          <Row>
            <Card style={{ width: '400px', zIndex: -1 }}>
            </Card>
          </Row>
        </Col>
      </div>
    );

   }

   else{

  return (
    <div style={{ justifyContent: "center", alignItems: "center" }}>
      <div className="center-div">
        <h3>Please share your final decision about the game</h3>
      </div>
      <div className="box">
          <div className="center-div">
            <h3>The highest rated card in the final round is shown below: {card}</h3>
          </div>
          {/* <br></br>
          <Row>
               <Card body style={{ width: '40%', left: '25%', height: '200px', backgroundColor: '#31a24c', color: "white", }}>{card}</Card>
           </Row> */}
           <br></br>
           <div className="card-container">
                <h2>The cards played by all the players are:</h2>
                {
                    // First, group the messages by playerNo
                    Object.entries(
                        messages_1.reduce((grouped, message) => {
                            (grouped[message.playerNo] = grouped[message.playerNo] || []).push(message);
                            return grouped;
                        }, {})
                    ).map(([playerNo, playerMessages]) => (
                        <div key={playerNo}>
                            <h3>{playerNo}:</h3>
                            <div className="card-grid">
                                {playerMessages.map(message => (
                                    <button className="send-message-button" disabled key={message.card}>
                                        <div style={{ fontSize: '14px', width: '200px' }}>{message.card}</div><br/>
                                        <div style={{ fontSize: '14px', width: '200px' }}>{message.description}</div>
                                    </button>
                                ))}
                            </div>
                        </div>
                    ))
                }
            </div>
        <Form>
        <Form.Group className="mb-3" controlId="q1">
          <Form.Label>{gameEndQuestions[0]}</Form.Label><br/>
          <span style={{display: "inline-block"}}>
          <span style={{ display: "inline-block", margin: "10px" }}>
          <Form.Check inline id="game1" value={gameEndQuestions[1].split(',')[0]} label={gameEndQuestions[1].split(',')[0]}  type='radio'  checked={geq1 == gameEndQuestions[1].split(',')[0]} onChange={e => setGEQ1(e.target.value)}/>
               <Form.Check inline id="game2" value={gameEndQuestions[1].split(',')[1]} label={gameEndQuestions[1].split(',')[1]}  type='radio'  checked={geq1 == gameEndQuestions[1].split(',')[1]} onChange={e => setGEQ1(e.target.value)}/>
               <Form.Check inline id="game3" value={gameEndQuestions[1].split(',')[2]} label={gameEndQuestions[1].split(',')[2]}  type='radio'  checked={geq1 == gameEndQuestions[1].split(',')[2]} onChange={e => setGEQ1(e.target.value)}/>
               <Form.Check inline id="game4" value={gameEndQuestions[1].split(',')[3]} label={gameEndQuestions[1].split(',')[3]}  type='radio'  checked={geq1 == gameEndQuestions[1].split(',')[3]} onChange={e => setGEQ1(e.target.value)}/>
               {/* <Form.Check inline value={midGameQuestions[6].split(',')[4]} label={midGameQuestions[6].split(',')[4]}  type='radio'  checked={q1 == midGameQuestions[6].split(',')[4]} onChange={e => setQ1(e.target.value)}/>       */}
            </span>
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="q1">
          <Form.Label>What do you think about the helpfulness of the cards presented by the player?</Form.Label><br/>
          <li>
          <Form.Label>{filteredMembers[0]['number']}</Form.Label><br/>
          <span style={{display: "inline"}}>
          <span style={{ display: "inline", margin: "10px" }}>
          <Form.Check inline id="q1-Vunhelpful" value="Very Unhelpful" label="Very Unhelpful"  type='radio'  checked={q1 == "Very Unhelpful"} onChange={e => setQ1(e.target.value)}/>
               <Form.Check inline id="q1-unhelpful" value="Unhelpful" label="Unhelpful"  type='radio'  checked={q1 == "Unhelpful"} onChange={e => setQ1(e.target.value)}/>
               <Form.Check inline id="q1-neutral" value="Neutral" label="Neutral"  type='radio'  checked={q1 == "Neutral"} onChange={e => setQ1(e.target.value)}/>
               <Form.Check inline id="q1-helpful" value="Helpful" label="Helpful"  type='radio'  checked={q1 == "Helpful"} onChange={e => setQ1(e.target.value)}/>
               <Form.Check inline id="q1-vhelpful" value="Very Helpful" label="Very Helpful"  type='radio'  checked={q1 == "Very Helpful"} onChange={e => setQ1(e.target.value)}/>
            </span>
          </span><br/><br/></li>
          <li>
          <Form.Label>{filteredMembers[1]['number']}</Form.Label><br/>
          <span style={{display: "inline"}}>
          <span style={{ display: "inline", margin: "10px" }}>
          <Form.Check inline id="q2-Vunhelpful" value="Very Unhelpful" label="Very Unhelpful"  type='radio'  checked={q2 == "Very Unhelpful"} onChange={e => setQ2(e.target.value)}/>
               <Form.Check inline id="q2-unhelpful" value="Unhelpful" label="Unhelpful"  type='radio'  checked={q2 == "Unhelpful"} onChange={e => setQ2(e.target.value)}/>
               <Form.Check inline id="q2-neutral" value="Neutral" label="Neutral"  type='radio'  checked={q2 == "Neutral"} onChange={e => setQ2(e.target.value)}/>
               <Form.Check inline id="q2-helpful" value="Helpful" label="Helpful"  type='radio'  checked={q2 == "Helpful"} onChange={e => setQ2(e.target.value)}/>
               <Form.Check inline id="q2-vhelpful" value="Very Helpful" label="Very Helpful"  type='radio'  checked={q2 == "Very Helpful"} onChange={e => setQ2(e.target.value)}/>
            </span>
          </span><br/><br/></li>
          <li>
          <Form.Label>{filteredMembers[2]['number']}</Form.Label><br/>
          <span style={{display: "inline"}}>
          <span style={{ display: "inline", margin: "10px" }}>
          <Form.Check inline id="q3-Vunhelpful" value="Very Unhelpful" label="Very Unhelpful"  type='radio'  checked={q3 == "Very Unhelpful"} onChange={e => setQ3(e.target.value)}/>
               <Form.Check inline id="q3-unhelpful" value="Unhelpful" label="Unhelpful"  type='radio'  checked={q3 == "Unhelpful"} onChange={e => setQ3(e.target.value)}/>
               <Form.Check inline id="q3-neutral" value="Neutral" label="Neutral"  type='radio'  checked={q3 == "Neutral"} onChange={e => setQ3(e.target.value)}/>
               <Form.Check inline id="q3-helpful" value="Helpful" label="Helpful"  type='radio'  checked={q3 == "Helpful"} onChange={e => setQ3(e.target.value)}/>
               <Form.Check inline id="q3-vhelpful" value="Very Helpful" label="Very Helpful"  type='radio'  checked={q3 == "Very Helpful"} onChange={e => setQ3(e.target.value)}/>
            </span>
          </span><br/><br/></li>
        </Form.Group>
        </Form>
      </div>
      <div className="center-div">
        <Button variant="primary" type="submit" onClick={(e) => handleSubmit(e)}>
          Submit Responses
        </Button>
      </div>
    </div>
  );
   }
};

export default EndgameSurvey;
